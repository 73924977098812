import { useEffect, useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import { ApiRequests } from '../services/ApiRequests';

// function VideoboardModel({show, setShow,video,username}) {
//     const [category,setCategory] = useState()
//     const [subCategory,setSubCategory] = useState("")
//     const [categories,setCategories] = useState([])
//     const [subCategories,setSubCategories] = useState([])
//     const [board,setBoard] = useState()
//   const token = localStorage.getItem("app-access-token");

//   const handleClose = () => setShow(false);
//   const fetchBoard =async()=>{
//     const response = await ApiRequests.getUserBoard(token)
//     if(response.status===200){
//         setBoard(response.data)
//         setCategories(response?.data?.categories)
//         setCategory(response?.data?.categories[0])
//     }else{
//         setBoard()
//     }
//   }
//   useEffect(()=>{
//     fetchBoard()
//   },[])
//   useEffect(()=>{
//     setSubCategories(category?.subCategories)
//   },[category])
// const submit = async()=>{
//     const data = {
//         video,
//         username,
//         categories:[category],
//         subCategories:[subCategory],
//         boardId:board?board.id?board.id:board._id:null
//     }
//     const resp = await ApiRequests.createBoardVideo({token,data})
//     console.log("Video created successfully", resp.data)
// handleClose()
// }
//   return (
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>{board?.name||"Default"}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//             {categories?
//             <select onChange={(e)=>setCategory(JSON.parse(e.target.value))}>
//             {categories?.map((cat)=>
//             <option value={JSON.stringify(cat)}>{cat.name}</option>
//             )}
//             </select>
//         :
//         <input type='text' onChange={(e)=>setCategory(e.target.value)} />}
//          {subCategories?
//             <select onChange={(e)=>setSubCategory(e.target.value)}>
//             {categories?.map((cat)=>
//             <option value={cat}>{cat}</option>
//             )}
//             </select>
//         :
//         <input type='text' onChange={(e)=>setSubCategory(e.target.value)} />}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={submit}>
//             Submit
//           </Button>
//         </Modal.Footer>
//       </Modal>
//   );
// }

// export default VideoboardModel;

// import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReactSelect from './react-select';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'black',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function VideoboardModel({ show, platform = "tiktok", setShow, video, username }) {
  const [category, setCategory] = useState(null)
  const [subCategory, setSubCategory] = useState("")
  const [categories, setCategories] = useState([])
  const [filterCategories, setFilterCategories] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [subCategories, setSubCategories] = useState([])
  const [filterSubCategories, setFilterSubCategories] = useState([])
  const [subInputValue, setSubInputValue] = useState("")
  const [selectedValues, setSelectedValues] = useState([]);
  const [board, setBoard] = useState()
  const token = localStorage.getItem("app-access-token");
  const handleClose = () => setShow(false);
  const fetchBoard = async () => {

    try {
      const response = await ApiRequests.getUserBoard(token)
      if (response.status === 200) {
        setBoard(response.data)
        setCategories(response?.data?.categories)
        setFilterCategories(response?.data?.categories)
        // setCategory(response?.data?.categories[0])
      } else {
        setBoard()
      }
    } catch (error) {
      console.log('error fetching board for user', error)
    }
  }

  console.log('selected values  ', selectedValues)
  useEffect(() => {
    fetchBoard()
  }, [show])
  useEffect(() => {
    setSubCategories(category?.subCategories)
    setFilterSubCategories(category?.subCategories)
  }, [category])
  const submit = async () => {
    const data = {
      platform,
      video: { ...video, videoid: video.id ? video.id : video['Video ID'] },
      username,
      categories: category?.name === inputValue ? [category?.name] : [inputValue],
      subCategories: selectedValues?.length ? selectedValues : [subInputValue],
      boardId: board ? board.id ? board.id : board._id : " "
    }
    if (data.categories[0] === "") {
      toast.error("Please select category")
      return
    }
    const resp = await ApiRequests.createBoardVideo({ token, data })
    console.log("Video Saved Successfully", resp.data)
    handleClose()
  }
  return (
    <>
      <Modal
        className='add-folder-modal'
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ width: '600px' }} sx={style}>
          <Typography style={{ fontSize: '24px' }} id="modal-modal-title" variant="h6" component="h2">
            {"My Board"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {/* {!category && */}
            <ReactSelect type='Folder' options={filterCategories || []}
              handleInputChange={(e) => {
                console.log("this is category change", e.target.value)
                setInputValue(e.target.value)
                setFilterSubCategories([])
                setSubInputValue('')
                // setFilterCategories(categories.filter((a) => a.name.includes(e.target.value)))
              }}
              heading={"Folder"}
              inputValue={inputValue}
              handleChange={(e) => {
                console.log("this is category", e)
                setInputValue(e.name)
                setCategory(e)
              }}
            />
            {/* // } */}
            <br />
            {/* {category && */}
            {(category || inputValue) && <ReactSelect type='Sub Folder' options={filterSubCategories || []}
              handleInputChange={(e) => {
                console.log("this is category change", e.target.value)
                setSubInputValue(e.target.value)
                // setFilterSubCategories(subCategories.filter((a) => a.includes(e.target.value)))
              }}
              inputValue={subInputValue}
              heading={"Sub Folder"}

              // handleChange={(e) => {
              //   console.log("this is category", e)
              //   setSubInputValue(e)
              //   setSubCategory(e)
              // }} 
              handleCheckboxChange={(e) => {
                setSubInputValue(e)
                const isSelected = selectedValues.includes(e);

                if (isSelected) {
                  setSelectedValues(selectedValues.filter((value) => value !== e));
                } else {
                  setSelectedValues([...selectedValues, e]);
                }
              }}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />}
            {/* } */}
          </Typography>
          <Button className='btn-model' style={{ width: '100%', padding: '10px', border: '1px solid lightgray', fontSize: '17px ,', fontWeight: '500px', marginTop: '15px', color: "white" }} onClick={submit}>Save</Button>
        </Box>
      </Modal>
    </>
  );
}
