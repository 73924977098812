import React from 'react'

import purpleGraph from '../assets/downloadPageImgs/purple-graph.png'
import yellowGraph from '../assets/downloadPageImgs/yellow-graph.png'
import greenGraph from '../assets/downloadPageImgs/green-graph.png'
import cardGraph from '../assets/downloadPageImgs/card-graph.png'

export default function TotalDetailCards(){
    return(
        <section id='total-detail'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6'>
                            <div className='total-card-wrapper red'>
                                <div className='text'>
                                    <p>TOTAL VIEWS</p>
                                    <strong>70.5M</strong>
                                </div>
                                <div className='graph-wrapper'>
                                    <img src={cardGraph} alt='cardGraph' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='total-card-wrapper purple'>
                                <div className='text'>
                                    <p>TOTAL LIKES</p>
                                    <strong>2.1M</strong>
                                </div>
                                <div className='graph-wrapper'>
                                    <img src={purpleGraph} alt='cardGraph' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='total-card-wrapper yellow'>
                                <div className='text'>
                                    <p>TOTAL SHARES</p>
                                    <strong>0</strong>
                                </div>
                                <div className='graph-wrapper'>
                                    <img src={yellowGraph} alt='cardGraph' />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='total-card-wrapper green'>
                                <div className='text'>
                                    <p>TOTAL COMMENTS</p>
                                    <strong>13.8M</strong>
                                </div>
                                <div className='graph-wrapper'>
                                    <img src={greenGraph} alt='cardGraph' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}