



import React from 'react'

function Footer() {
  return (
    <div className="footer-1-dark-with-cta snipcss-dyoW1 pt-20 footer-container">
  <div className="container-lg">
    <div className="w-layout-grid footer-grid">
      <div className="w-layout-grid footer-1-main-grid">
        <a id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdbe-2fe08bdf"  className=" cursor-pointer footer-1-logo w-inline-block">
            {/* <div className='bg-gray-800 rounded-lg text-gray-600 font-bold text-lg' >LOGO</div> */}
            <img style={{width:'max-content'}} src="https://uploads-ssl.webflow.com/6418b3596d3d92089dfdd6f5/642b64f5e724a0b23467db25_tokbackup%401x.png" loading="lazy" alt="" className="image-contain" />

        </a>
        <div id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc0-2fe08bdf">
          <div className="w-layout-grid footer-1-sub-grid">
            <div className="w-layout-grid column-sm" style={{textAlign:'left'}}>
              <div id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc3-2fe08bdf" className="margin-bottom margin-3">
                <h6 className="footer-1-nav-heading">
                  Product 
                </h6>
              </div>
              <a id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc6-2fe08bdf" href={"https://www.tokbackup.com/pricing"} target={'_blank'} className=" cursor-pointer footer-1-link-gray">
                Pricing
              </a>
              <a id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc8-2fe08bdf" href={"https://www.tokbackup.com/"} target={'_blank'}  className=" cursor-pointer footer-1-link-gray">
                Features
              </a>
              <a id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdca-2fe08bdf" href={"https://www.tokbackup.com/"} target=" _blank" className="cursor-pointer footer-1-link-gray">
                Chrome Extension
              </a>
            </div>
            <div className="w-layout-grid column-sm" style={{textAlign:'left'}}>
              <div id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdcd-2fe08bdf" className="margin-bottom margin-3">
                <h6 className="footer-1-nav-heading">
                  <strong>
                    Support
                  </strong>
                </h6>
              </div>
              <a id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdd0-2fe08bdf" href={"https://www.tokbackup.com/"}   target=" cursor-pointer _blank" className="footer-1-link-gray">
                Help Docs
              </a>
              <a id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdd2-2fe08bdf" href={"https://www.tokbackup.com/"}   target=" cursor-pointer _blank" className="footer-1-link-gray">
                Video Tutorials
              </a>
              <a id="w-node-_73076f7e-6cc9-cc7a-d25c-ae6f209eb5cf-2fe08bdf" href={"https://www.tokbackup.com/"}   target=" cursor-pointer _blank" className="footer-1-link-gray">
                Feedback
              </a>
              <a id="w-node-b7133bbf-98fd-9d6a-32ef-e289edefea05-2fe08bdf" href={"https://www.tokbackup.com/"}   className=" cursor-pointer footer-1-link-gray">
                Contact Us
              </a>
            </div>
            <div className="w-layout-grid column-sm" style={{textAlign:'left'}}>
              <div id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbddd-2fe08bdf" className="margin-bottom margin-3">
                <h6 className="footer-1-nav-heading">
                  Company
                </h6>
              </div>
              <a id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbde0-2fe08bdf" href={"https://tokbackup.lemonsqueezy.com/affiliates/"} target={'_blank'}  className=" cursor-pointer footer-1-link-gray">
                Affiliates
              </a>
              <a id="w-node-_26f998d6-8cdc-e3e3-ebf9-2d5847978311-2fe08bdf"  href={"https://www.tokbackup.com/"}   target=" cursor-pointer _blank" className=" cursor-pointer footer-1-link-gray">
                Refund Policy
              </a>
              <a id="w-node-f377beec-5f46-98d4-55de-6fae8da1f116-2fe08bdf" href={"https://www.tokbackup.com/"}   target=" cursor-pointer _blank"  className=" cursor-pointer footer-1-link-gray">
                Privacy Policy
              </a>
              <a id="w-node-_5bc4843a-1d2d-e49c-05c8-2b00075cf7c6-2fe08bdf" href={"https://www.tokbackup.com/"}   target=" cursor-pointer _blank"  className=" cursor-pointer footer-1-link-gray">
                Terms &amp; Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdfd-2fe08bdf" className="footer-1-meta">
        <div className="w-layout-grid row-base column-mobile">
          <a id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdff-2fe08bdf"  className=" cursor-pointer footer-1-link-gray">
            © TokBackup 2023
          </a>
        </div>
      </div>
    </div>
  </div>
</div>  )
}

export default Footer