const ShortenNumber = (number) => {
    if (number === undefined || number === null) {
      return '0';
    }
  
    number = Number(number?.toString().replace(/[^\d.]/g, ''));
  
    if (number === 0 || isNaN(number)) {
      return '0';
    }
  
    if (number < 1000) {
      return number.toFixed(0);
    }
    if (number < 1000000) {
      return (number / 1000).toFixed(1) + 'k';
    }
    if (number < 1000000000) {
      return (number / 1000000).toFixed(1) + 'm';
    }
    if (number < 1000000000000) {
      return (number / 1000000000).toFixed(1) + 'b';
    }
  
    return '0';
  };
  
  export default ShortenNumber;
  