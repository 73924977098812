import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { auth } from "../firebase-config";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { useNavigate } from "react-router-dom";
import AddNewUserToDatabase from "../utilities/AddNewUserToDatabase";
import LoggedInOrNot from "../utilities/LoggedInOrNot";
import useAuth from "../hooks/useAuth";
import { useDispatch } from "react-redux";
import { userRegisterAsyncThunk } from "../store/authSlice";
import { toast } from "react-toastify";

function JoinModal({
  joinModalOpenRef,
  loginModalOpenRef,
  paymentModalOpenRef,
}) {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let userSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const [joinLoading, setJoinLoading] = useState(false);

  const handleJoin = async (
    values,
    { setSubmitting, setFieldError, resetForm }
  ) => {
    const { name, email, password } = values;
    try {
      await userSchema.validate(values);
      const { payload } = await dispatch(
        userRegisterAsyncThunk({ name, email, password })
      );
      const errorMessage =
        payload?.response?.data?.code !== 201
          ? payload?.response?.data?.message
          : null;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        resetForm();
        joinModalOpenRef.current.click();
      }
      paymentModalOpenRef.current.click();
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        for (const fieldError of err.errors) {
          setFieldError(fieldError.split(":")[0], fieldError);
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary hidden "
        data-bs-toggle="modal"
        data-bs-target="#exampleModal2"
        ref={joinModalOpenRef}
      >
        OPEN MODAL{" "}
      </button>

      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModal2Label"
        aria-hidden="true"
      >
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-gray-900 bg-clip-padding rounded-md outline-none text-current p-10">
            <div>
              <h1
                className="text-2xl font-semibold text-gray-800 mb-12"
                style={{ color: "#ccdae7" }}
              >
                Create Account
              </h1>

              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={userSchema}
                onSubmit={handleJoin}
                innerRef={formikRef}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-group mb-6">
                      <Field
                        type="text"
                        name="name"
                        placeholder="Name"
                        style={{ width: "80%", color: "#ccdae7" }}
                        className="css-5g0doo bg-gray-800 text-white p-2 text-xs p-2   rounded-2xl     transition ease-in-out border border-gray-700    focus:text-gray-700  focus:border-blue-600 focus:outline-none"
                      />
                      <ErrorMessage
                        name="name"
                        component="p"
                        className="text-red-500 text-xs text-left mt-1"
                        style={{ marginLeft: 50 }}
                      />
                    </div>

                    <div className="form-group mb-6">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                        style={{ width: "80%", color: "#ccdae7" }}
                        className="css-5g0doo bg-gray-800 text-white p-2 text-xs p-2   rounded-2xl     transition ease-in-out border border-gray-700    focus:text-gray-700  focus:border-blue-600 focus:outline-none"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="text-red-500 text-xs text-left mt-1"
                        style={{ marginLeft: 50 }}
                      />
                    </div>

                    <div className="form-group mb-6">
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        style={{ width: "80%", color: "#ccdae7" }}
                        className="css-5g0doo bg-gray-800 text-white p-2 text-xs p-2   rounded-2xl     transition ease-in-out border border-gray-700    focus:text-gray-700  focus:border-blue-600 focus:outline-none"
                      />
                      <ErrorMessage
                        name="password"
                        component="p"
                        className="text-red-500 text-xs text-left mt-1"
                        style={{ marginLeft: 50 }}
                      />
                    </div>
                    <div className="form-group mb-6">
                      <Field
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        style={{ width: "80%", color: "#ccdae7" }}
                        className="css-5g0doo bg-gray-800 text-white p-2 text-xs p-2   rounded-2xl     transition ease-in-out border border-gray-700    focus:text-gray-700  focus:border-blue-600 focus:outline-none"
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="p"
                        className="text-red-500 text-xs text-left mt-1"
                        style={{ marginLeft: 50 }}
                      />
                    </div>

                    {/* {errorMessage && (
                      <p className="text-red-500 text-xs text-center mb-1">
                        {errorMessage}
                      </p>
                    )} */}

                    <button
                      type="submit"
                      style={{
                        opacity: isSubmitting ? "0.5" : "1",
                        width: "200px",
                        color: "#ccdae7",
                      }}
                      className="w-full px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Loading..." : "Sign Up"}
                    </button>

                    <p className=" mt-6 text-center text-sm">
                      Already a member?{" "}
                      <a
                        href="#!"
                        style={{ color: "#818995" }}
                        className=" hover:text-blue-400 focus:text-blue-700 transition duration-200 ease-in-out text-sm"
                        data-bs-dismiss="modal"
                        data-bs-target="#exampleModal2"
                        onClick={() => {
                          loginModalOpenRef.current.click();
                        }}
                      >
                        Login
                      </a>
                    </p>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinModal;
