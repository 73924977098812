import axios from "axios";
import { APIurls, basePath } from "./constant";

const apiService = axios.create();
apiService.defaults.baseURL = basePath;
apiService.defaults.timeout = 35000;

// Add a variable to track whether a token refresh is in progress
let isRefreshing = false;

// Create an array to store the requests that are waiting for a new token
let refreshSubscribers = [];

// Function to refresh the token
async function refreshAccessToken() {
  try {
    const refreshToken = localStorage.getItem("app-refresh-token");
    const response = await apiService.post(APIurls.refreshTokens, {
      refreshToken,
    });

    // Update the access token in local storage
    const newAccessToken = response.data;
    localStorage.setItem("app-access-token", newAccessToken);
    localStorage.setItem("app-access-token", newAccessToken?.access.token);
    localStorage.setItem("app-access", JSON.stringify(newAccessToken?.access));
    localStorage.setItem(
      "app-refresh",
      JSON.stringify(newAccessToken?.refresh)
    );
    localStorage.setItem("app-refresh-token", newAccessToken?.refresh?.token);
    localStorage.setItem("user", JSON.stringify(newAccessToken?.user));

    // Set isRefreshing to false, indicating that the token refresh is complete
    isRefreshing = false;

    // Retry all the requests that were waiting for a new token
    refreshSubscribers.forEach((cb) => cb(newAccessToken));
    refreshSubscribers = [];
  } catch (error) {
    console.error("Error refreshing access token:", error);

    // If all refresh attempts fail, remove local storage
    localStorage.clear();

    // Set isRefreshing to false to prevent further attempts
    isRefreshing = false;

    // Reject all the requests that were waiting for a new token
    refreshSubscribers.forEach((cb) => cb(null));
    refreshSubscribers = [];

    // Handle refresh token failure, e.g., redirect to login page
  }
}

// Intercept requests and add the access token to the headers
apiService.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("app-access-token");
    config.headers = {
      Accept: "application/json, text/plain, */*",
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "access-control-allow-headers": "*",
      "access-control-allow-methods": "*",
      "access-control-allow-origin": "*",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercept responses and handle 401 errors
apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired token (401 Unauthorized)
    if (error.response.status === 401 && !originalRequest._retry) {
      // If token refresh is not already in progress, initiate the refresh
      if (!isRefreshing) {
        isRefreshing = true;
        await refreshAccessToken();
      }

      // Create a promise that will be resolved with the new access token
      const retryOriginalRequest = new Promise((resolve) => {
        // Add the callback to the array of subscribers waiting for a new token
        refreshSubscribers.push((newAccessToken) => {
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          resolve(apiService(originalRequest));
        });
      });

      // Set _retry to true to avoid an infinite loop
      originalRequest._retry = true;

      // Return the promise that will retry the original request after token refresh
      return retryOriginalRequest;
    }

    // If the error is not due to an expired token, reject the promise with the error
    return Promise.reject(error);
  }
);

export default apiService;
