import React from "react";
import { useDispatch } from "react-redux";
import { userLogoutAsyncThunk } from "../store/authSlice";

const Logout = () => {
  const dispatch = useDispatch();

  dispatch(userLogoutAsyncThunk());
  return <div>Logout</div>;
};

export default Logout;
