import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { auth, db } from "../firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { redirect, useNavigate } from "react-router-dom";
import LoggedInOrNot from "../utilities/LoggedInOrNot";
import {
  userLoginAsyncThunk,
  userRegisterAsyncThunk,
} from "../store/authSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { lemon_path } from "../services/constant";
import { useLocation } from "react-router-dom";
import { ApiRequests, paymentApiRequests } from "../services/ApiRequests";

function Joinpage({ loginModalRef }) {
  const location = useLocation(); // Use useLocation to access the current location
  const token = localStorage.getItem("app-access-token");
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var productId;
  const getQueryParam = (name) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(name);
  };

  const getProductById = async () => {
    try {
      const { data } = await ApiRequests.getProduct({
        id: productId,
        token,
      });
      return data?.data;
    } catch (error) {
      console.log("error getting product ", error);
    }
  };

  useEffect(() => {
    const redirectIfLogedIn = async () => {
      productId = getQueryParam("subscription");
      if (productId) {
        console.log("Product ID:", productId);
  
        if (user) {
          if(user.subscribed){
            navigate('/pricing')
          }else{
            try {
              const product = await getProductById();            
              if (product) {
                window.location.replace(
                  `${product?.attributes?.buy_now_url}?checkout[email]=${user?.email}&checkout[name]=${user?.name}`
                );
              }
            } catch (error) {
              console.error('Error fetching product:', error);
            }
          }
        }
      }
    };
  
    redirectIfLogedIn();
  }, [location.search]);
  

  let schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });


  // `?checkout[email]=${user.email}&checkout[name]=${user.name}`
  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    let beginnerMonthly,
      beginnerAnnually,
      creatorMonthly,
      creatorAnnually,
      teamMonthly,
      teamAnnually;
    try {
      const { name, email, password } = values;
      await schema.validate(values);
      const { payload } = await dispatch(
        userRegisterAsyncThunk({ name, email, password })
      );
      const errorMessage =
        payload?.response?.data?.code !== 201
          ? payload?.response?.data?.message
          : null;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        if (productId) {
          try {
            const product = await getProductById();
            const { payload } = await dispatch(
              userLoginAsyncThunk({
                user: { email, password },
              })
            );
            if (payload.user) {
              if (product) {
                window.location.replace(
                  `${product.attributes.buy_now_url}?checkout[email]=${email}&checkout[name]=${name}`
                );
              }
            }
          } catch (error) {
            console.log("error: ", error);
          }
        }
      }
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        for (const fieldError of err.errors) {
          const fieldName = fieldError.split(":")[0];
          setFieldError(fieldName, fieldError);
        }
      }
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div>
      <div className="LoginForm">
        <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm m-auto w-[90%] mt-[75px]">
          <div>
            <h1 className="text-2xl font-semibold  mb-6">Sign Up</h1>

            <Formik
              initialValues={{
                name: "",
                email: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group mb-6">
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        marginLeft: 10,
                      }}
                      htmlFor="exampleInputEmail2"
                      className="form-label inline-block mb-1 "
                    >
                      Full name
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="exampleInputEmail2"
                      aria-describedby="emailHelp"
                      placeholder="Enter name"
                    />
                    <ErrorMessage
                      name="name"
                      component="p"
                      className="text-red-500 text-xs text-left mt-1"
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  <div className="form-group mb-6">
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        marginLeft: 10,
                      }}
                      htmlFor="exampleInputEmail2"
                      className="form-label inline-block mb-1 "
                    >
                      Email address
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="exampleInputEmail2"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                    <ErrorMessage
                      name="email"
                      component="p"
                      className="text-red-500 text-xs text-left mt-1"
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  <div className="form-group mb-6">
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        marginLeft: 10,
                      }}
                      htmlFor="exampleInputPassword2"
                      className="form-label inline-block mb-1"
                    >
                      Password
                    </label>
                    <Field
                      type="password"
                      name="password"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="exampleInputPassword2"
                      placeholder="Password"
                    />
                    <ErrorMessage
                      name="password"
                      component="p"
                      className="text-red-500 text-xs text-left mt-1"
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  <div className="form-group mb-6">
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        marginLeft: 10,
                      }}
                      htmlFor="exampleInputPassword2"
                      className="form-label inline-block mb-1 "
                    >
                      Confirm Password
                    </label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="exampleInputPassword2"
                      placeholder="Confirm Password"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="p"
                      className="text-red-500 text-xs text-left mt-1"
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  <button
                    type="submit"
                    style={{
                      opacity: isSubmitting ? "0.5" : "1",
                      width: "200px",
                      color: "#ccdae7",
                    }}
                    className="w-full px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Loading..." : "Sign Up"}
                  </button>
                </Form>
              )}
            </Formik>

            <p className=" mt-6 text-center">
              Already a member?{" "}
              <a
                href=""
                style={{ color: "#818995" }}
                className="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Joinpage;
