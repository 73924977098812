/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from "react-icons/ai";
import Loader from './Loader';
import { FaBookOpen, FaList } from "react-icons/fa";
import { BiGrid } from "react-icons/bi";
import clsx from 'clsx';
import ShortenNumber from '../utilities/ShortenNumber';
import GetTimeAgo from '../utilities/GetTimeAgo';
import { Col, Container, Row } from 'react-bootstrap';
import { BsBookmarkFill, BsCalendarCheckFill, BsClock, BsClockFill, BsFillChatFill, BsHeartFill, BsPlayFill, BsShareFill } from 'react-icons/bs';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { ApiRequests } from '../services/ApiRequests';
import { useDispatch, useSelector } from 'react-redux';
import VideoboardModel from './VideoboardModel';
import { basePath } from '../services/constant';
import axios from 'axios';
// import { getUserBoardVideosAsyncThunk } from '../store/boardSlice';

const Header = ({
  column,
  i,
  requestSort,
  selectedVideos,
  setSelectedVideos,
  pageData,
}) => {
  return (
    <th
      key={i}
      scope="col"
      className={clsx(
        "px-6 py-3 text-gray-400 hover:text-gray-200 transition ease-in",
        column !== "title" && column !== "select" && "cursor-pointer"
      )}
      style={{
        width: "1%",
        whiteSpace: "nowrap",
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      onClick={() =>
        column !== "title" && column !== "select" && requestSort(column)
      }
    >
      {column === "select" ? (
        <input
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedVideos(new Set(pageData?.map((item) => item.id)));
            } else {
              setSelectedVideos(new Set());
            }
          }}
        />
      ) : (
        column
      )}
    </th>
  );
};
function SearchIcon() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="search"
      style={{ marginTop: "-8px", marginLeft: "-40px" }}
      className="text-gray-500 absolute w-4 cursor-pointer"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
      ></path>
    </svg>
  );
}

const ListRow = ({
  item,
  i,
  columns,
  selectedVideos,
  setSelectedVideos,
  platform,
}) => {
  console.log('columns ', columns)
  return (
    <tr key={i} className="border-b bg-gray-900 border-gray-800">
      {columns.map((column, i) => {
        let cellContent;
        if (!global.DEV && column == "id") {
          return;
        } else if (column === "select") {
          cellContent = (
            <input
              type="checkbox"
              checked={selectedVideos.has(
                platform == "instagram" ? item["Video ID"] : item.id
              )}
              onChange={(e) => {
                const updatedSelection = new Set(selectedVideos);
                if (e.target.checked) {
                  updatedSelection.add(
                    platform == "instagram" ? item["Video ID"] : item.id
                  );
                } else {
                  updatedSelection.delete(
                    platform == "instagram" ? item["Video ID"] : item.id
                  );
                }
                setSelectedVideos(updatedSelection);
              }}
            />
          );
        } else if (
          [
            "views",
            "likes",
            "shares",
            "comments",
            "bookmarks",
            "Views",
            "Likes",
            "shares",
            "Comments",
          ].includes(column)
        ) {
          cellContent = ShortenNumber(item[column]);
        } else if (column === "duration") {
          cellContent = item.platform === "instagram" ? `${item.Duration}S` : item[column] + "s";
        } else if (column == "date") {
          cellContent = (
            <div>
              <div>{GetTimeAgo(new Date(item[column]))}</div>
              <div className="text-gray-500 hover:text-gray-200 transition ease-in text-xs">
                {new Date(item[column])?.toLocaleDateString()}
              </div>
            </div>
          );
        } else if (column === "title") {
          cellContent = (
            <a
              className="flex items-start cursor-pointer "
              style={{ minWidth: "200px" }}
              href={item.platform == "instagram" ? item.Link : item["link"]}
              target={"_blank"} rel="noreferrer"
            >
              <img
                src={
                  item.platform === "instagram"
                    ? `https://instagramserver.tokbackup.com/proxy?media_url=${encodeURIComponent(
                      item.thumbnail_url
                    )}`
                    : item["thumbnail"]
                }
                alt="Thumbnail"
                style={{
                  width: "40px",
                  height: "auto",
                  marginRight: "10px",
                  borderRadius: "2px",
                }}
              />
              <span
                style={{ marginTop: "10px" }}
                className="hover:text-gray-200 transition ease-in"
              >
                {item["title"]}
              </span>
            </a>
          );
        } else {
          cellContent = item[column];
        }

        return (
          <td
            key={i}
            className={`px-6 py-4 text-gray-300 ${column === "date" ? "date-min-width" : ""
              }`}
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span
              className={clsx(
                "text-gray-400 hover:text-gray-200 transition ease-in",
                column == "title" && "text-gray-200"
              )}
            >
              {cellContent}
            </span>
          </td>
        );
      })}
    </tr>
  );
};

const formatTime = (seconds) => {
  if (seconds < 60) {
    return `${seconds}s`;
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Use template literals to format the time string
  const timeString = `${minutes}m ${remainingSeconds}s`;

  return timeString;
};


function formatShotDate(mongoDate) {
  if (!(mongoDate instanceof Date)) {
    return "Invalid date";
  }

  const year = mongoDate.getFullYear();
  const month = mongoDate.getMonth() + 1; // Month is zero-based, so add 1
  const day = mongoDate.getDate();

  const formattedYear = String(year).slice(-2).padStart(2, "0");

  const formattedMonth = String(month).padStart(2, "0");
  const formattedDay = String(day).padStart(2, "0");

  const formattedDate = `${formattedMonth}/${formattedDay}/${formattedYear}`;

  return formattedDate;
}
const Rows = ({
  item,
  i,
  columns,
  selectedVideos,
  setSelectedVideos,
  platform,
  handleShow,
  setBoardVideo,
  boardVideo,
}) => {
  console.log("item: ", item);

  return false ? (
    <div
      class="youtube-video-card"
      style={{ position: "relative", height: "250px" }}
    >
      <a href={platform == "instagram" ? item.Link : item.link} target="_blank" rel="noreferrer">
        <img
          src={platform === "instagram" ? `https://instagramserver.tokbackup.com/proxy?media_url=${encodeURIComponent(
            item.thumbnail
          )}` : item.thumbnail}
          alt={item.caption}
          style={{
            width: "100%",
            height: "100%",
            display: "block",
            objectFit: "cover",
          }}
        />
      </a>
      {/* {platform === 'instagram' ? (
          <a href={item.link} target="_blank"><iframe
            src={item.url}  // Use the Instagram video URL from the item
            width="100%"
            height="100%"
            frameBorder="0"
          /></a>
        ) : (
          <a href={item.link} target="_blank">
            <img
              src={item.thumbnail}
              alt="Thumbnail not available"
              style={{ width: "100%", height: "100%", display: "block", objectFit: "cover" }}
            />
          </a>
        )} */}
      <div>
        <div className="chcekbox-box">
          <input
            className="form-control"
            type="checkbox"
            checked={selectedVideos.has(
              platform == "instagram" ? item["Video ID"] : `${item.id}`
            )}
            onChange={(e) => {
              const updatedSelection = new Set(selectedVideos);
              if (e.target.checked) {
                updatedSelection.add(
                  platform == "instagram" ? item["Video ID"] : `${item.id}`
                );
              } else {
                updatedSelection.delete(
                  platform == "instagram" ? item["Video ID"] : `${item.id}`
                );
              }
              setSelectedVideos(updatedSelection);
            }}
          />
        </div>
        <div
          class="caption"
          style={{
            position: "absolute",
            bottom: 20,
            right: 0,
            width: "50px",
            color: "white",
            padding: "8px 2px",
            fontSize: "14px",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          }}
        >
          <div className="caption-item">
            <BsHeartFill />
            <p>{ShortenNumber(item.likes)}</p>
          </div>
          <div className="caption-item">
            <BsFillChatFill />
            <p>{ShortenNumber(item.comments)}</p>
          </div>
          <div className="caption-item">
            <BsBookmarkFill />
            <p>{ShortenNumber(item.bookmarks)}</p>
          </div>
          <div className="caption-item">
            <BsShareFill />
            <p>{ShortenNumber(item.shares)}</p>
          </div>
          {platform !== "instagram" && (
            <div className="caption-item">
              <BsClockFill />
              {/* <p>{formatTime(item.video_duration)}</p> */}
              <p>{item.video_duration}s</p>
            </div>
          )}
          <div className="caption-item">
            <BsCalendarCheckFill />
            <p>{formatShotDate(new Date(item.date))}</p>
          </div>
        </div>

        <div
          className="durations"
          style={{
            position: "absolute",
            left: 0,
            bottom: 14,
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingRight: "6px",
          }}
        >
          <BsPlayFill size={25} />
          <p>{ShortenNumber(item.views)}</p>
        </div>
      </div>
    </div>
  ) : (
    <Col
      sx={12}
      md={6}
      className="column-grid"
      style={{ height: "450px", width: "350px" }}
    >
      <div
        class="caption-container"
        style={{ position: "relative", height: "100%" }}
      >
        <a
          href={platform === "instagram" ? item.Link : item.link}
          target="_blank" rel="noreferrer"
        >
          <img
            src={
              item.platform === "instagram"
                ? `https://instagramserver.tokbackup.com/proxy?media_url=${encodeURIComponent(
                  item.thumbnail_url
                )}`
                : item.thumbnail
            }
            alt={"Thumbnail not available"}
            style={{ width: "100%", display: "block", objectFit: "cover" }}
          />
        </a>
        <div style={{ border: "1px solid black" }}>
          {/* <div className="chcekbox-box">
            <input
              className="form-control"
              type="checkbox"
              checked={selectedVideos.has(
                platform == "instagram" ? item["Video ID"] : `${item.id}`
              )}
              onChange={(e) => {
                const updatedSelection = new Set(selectedVideos);
                if (e.target.checked) {
                  updatedSelection.add(
                    platform == "instagram" ? item["Video ID"] : `${item.id}`
                  );
                } else {
                  updatedSelection.delete(
                    platform == "instagram" ? item["Video ID"] : `${item.id}`
                  );
                }
                setSelectedVideos(updatedSelection);
              }}
            />
            <button
              onClick={() => {
                handleShow();
                setBoardVideo(item);
              }}
              className="model-btn"
            >
              <FaBookOpen />
            </button>
          </div> */}
          <div
            class="caption"
            style={{
              position: "absolute",
              bottom: 20,
              right: 0,
              width: "50px",
              color: "white",
              padding: "8px 2px",
              fontSize: "14px",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
            }}
          >
            <div className="caption-item">
              <BsHeartFill />
              <p>
                {ShortenNumber(
                  item.platform == "instagram" ? item.Likes : item.likes
                )}
              </p>
            </div>
            <div className="caption-item">
              <BsFillChatFill />
              <p>
                {ShortenNumber(
                  item.platform == "instagram" ? item.Comments : item.comments
                )}
              </p>
            </div>
            <div className="caption-item">
              <BsBookmarkFill size={32} />
              <p>{ShortenNumber(item.bookmarks)}</p>
            </div>
            <div className="caption-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m20 12l-6.4-7v3.5C10.4 8.5 4 10.6 4 19c0-1.167 1.92-3.5 9.6-3.5V19l6.4-7z"
                />
              </svg>{" "}
              <p>{ShortenNumber(item.shares)}</p>
            </div>
            {
              <div className="caption-item">
                <BsClock size={32} />
                <p>
                  {item.platform == "instagram"
                    ? item.Duration
                    : formatTime(item.duration)}
                </p>
                {/* <div>
              <div className="text-gray-500 hover:text-gray-200 transition ease-in text-xs">
                {new Date(item.date)?.toLocaleDateString()}
              </div>
            </div> */}
              </div>
            }
            <div className="caption-item">
              {/* <BsClock size={20} /> */}
              <div>
                {/* <div>{GetTimeAgo(new Date(item.date))}</div> */}
                <div className=" date hover:text-gray-200 transition ease-in text-xs ">
                  {formatShotDate(new Date(item.date || item["Date Posted"]))}
                </div>
              </div>
            </div>
          </div>

          <div
            className="durations"
            style={{
              position: "absolute",
              left: 0,
              bottom: 14,
              display: "flex",
              justifyContent: "space-around",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              borderTopRightRadius: 6,
              borderBottomRightRadius: 6,
              paddingTop: "6px",
              paddingBottom: "6px",
              paddingRight: "6px",
              color: "white",
            }}
          >
            <BsPlayFill size={25} />
            <p>
              {ShortenNumber(item.platform === "instagram" ? item.Views : item.views)}
            </p>
          </div>
        </div>
      </div>
    </Col>
  );
};
const getColumns = (platform, isDev, hideSelect) => {
  let baseColumns = [
    "select",
    "title",
    "views",
    "likes",
    "comments",
    "duration",
    "Likes",
    "Comments",
    "Views",
    "Duration",
    "shares",
    "bookmarks",
    "date",
    "Date Posted",
  ];

  // remove 'select' column if hideSelect is true
  if (hideSelect) {
    baseColumns = baseColumns.filter((column) => column !== "select");
  }
  if (platform == "youtube") {
    baseColumns = baseColumns.filter(
      (column) =>
        column !== "shares" &&
        column !== "Likes" &&
        column !== "Comments" &&
        column !== "Views" &&
        column !== "Duration" &&
        column !== "Date Posted"
    );
  }
  //remove bookmarks column if platofmr is not tiktok
  if (platform != "tiktok") {
    baseColumns = baseColumns.filter((column) => column !== "bookmarks");
  }
  if (platform == "tiktok") {
    baseColumns = baseColumns.filter(
      (column) =>
        column !== "Likes" &&
        column !== "Comments" &&
        column !== "Views" &&
        column !== "Duration" &&
        column !== "Date Posted"
    );
  }
  if (platform == "instagram") {
    baseColumns = baseColumns.filter(
      (column) =>
        column !== "likes" &&
        column !== "comments" &&
        column !== "views" &&
        column !== "duration" &&
        column !== "shares" &&
        column !== "date"
    );
  }
  return baseColumns;
};

const Videoboard = () => {
  // const { boardId } = useParams()
  // console.log("boardId", boardId)
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const sortFilters = [
    {
      id: 1,
      name: "Views",
      value: "views",
    },
    {
      id: 2,
      name: "Comments",
      value: "comments",
    },
    {
      id: 3,
      name: "Shares",
      value: "shares",
    },
    {
      id: 4,
      name: "Likes",
      value: "likes",
    },
    {
      id: 5,
      name: "Reset",
      value: "reset"
    },
  ];
  const params = useParams()
  const [selectedSortFilters, setSelectedSortFilters] = useState(new Set());
  const [sortConfig, setSortConfig] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [columns, setColumns] = useState([]);
  const [pageData, setPageData] = useState([
  ]);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [page, setPage] = useState(1);
  const [filterWithDays, setFilterWithDays] = useState(null);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [filteredCount, setFilteredCount] = useState(0); // Add a new state to store the count of filtered results
  const [videosView, setVideosView] = useState("Grid");
  const [numberOfDays, setNumberOfDays] = useState(null);
  const [category, setCategory] = useState(params.category ? { name: params.category, subCategories: [] } : { name: "reset", subCategories: [] })
  const [subCategory, setSubCategory] = useState(params.subcategory ? params.subcategory : "reset")
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [videoData, setVideoData] = useState([])
  const [backupVideos, setBackupvideos] = useState([])

  const [selectedSubFolders, setSelectedSubFolders] = useState(new Set())

  const [totalData, setTotalData] = useState(0)

  console.log('params ', params)

  console.log('sort config ', sortConfig)



  useEffect(() => {
    setSubCategories(category?.subCategories)
  }, [category])

  const fetchDataFromAPI = async (link) => {
    try {
      const response = await axios.post(`${basePath}/video-details?video=${link}`, {
        params: {
          mainUrlField: link, // Pass the mainUrlField as a parameter
        },
      });

      console.log('response from node', response.data)
      // setVideoData(response.data)

      // Update the state with the received data
      // setVideoData(response.data);
      return response.data
    } catch (error) {
      if (error.response) {
        console.log('error responee ', error.response)
        // toast.error(error.response.data.message)
      }
      console.error('Error fetching data from API:', error);
    }
  };

  const getInstagramMediaData = async (link) => {
    try {
      const response = await axios.get(`https://instagramserver.tokbackup.com/get_single_media_info?media_id=${link}`)

      console.log('response from insta python', response.data)
      // setVideoData(response.data)

      // Update the state with the received data
      // setVideoData(response.data);
      return response.data
    } catch (error) {
      console.error('Error fetching data from API:', error);
    }
  };


  // useEffect(() => {
  //   // fetchDataFromAPI()
  //   // getInstagramMediaData()
  // }, [])

  useEffect(() => {
    const fetchDataForPageData = async () => {
      setLoadingVideos(true)
      const dataPromises = pageData.map(async (item) => {
        if(item.platform == "instagram"){
          console.log('item for insta ', item)
          const mediaData = await getInstagramMediaData(item.video['Video ID']);
          console.log('meta data ', mediaData)
          return {...item, video: {...item.video, thumbnail_url: mediaData.thumbnail_url}}
        }
        if (item.platform == "youtube") {
          return item;
        }
        return await fetchDataFromAPI(item.video.link);
      });

      // Wait for all API calls to complete
      const fetchedData = await Promise.all(dataPromises);
      console.log('fetchedData', fetchedData)

      // Filter out null values (in case of errors)
      const validData = fetchedData.filter((data) => data !== null);

      // Store the valid data in videoData state
      setVideoData(validData);
      setBackupvideos(validData)
      setLoadingVideos(false)
    };

    fetchDataForPageData();
  }, [pageData]);

  const fetchVideos = async () => {
    // setLoadingVideos(true)
    const token = localStorage.getItem("app-access-token");
    console.log('params ', params)
    let data = { token, boardId: params.boardId, videoid: params.videoid, page, limit: rowsPerPage, category, subcategory: Array.from(selectedSubFolders).join(','), username: searchTerm }
    if (data?.category?.name) {
      data.category = data?.category?.name
    }
    if (data?.category === "reset") {
      delete data?.category
      delete data?.subcategory
    }
    if (selectedSubFolders.has('reset')) {
      delete data.subcategory
    }
    const resp = await ApiRequests.getSharedVideoBoard(data)
    if (resp.status === 200) {
      console.log("This is response", resp.data)
      setPageData(resp.data.results)
      console.log('resp.data.totalResults',resp.data.totalResults)
      setTotalData(resp.data.totalResults)
      // setLoadingVideos(false)
    }
  }
  const fetchBoard = async () => {
    setLoadingVideos(true)
    const token = localStorage.getItem("app-access-token");
    const response = await ApiRequests.getSharedBoard(params.boardId, token)
    if (response.status === 200) {
      setCategories(response?.data?.categories)
      // setLoadingVideos(false)
      // setCategory(response?.data?.categories[0])
    }
  }
  console.log("pageData", loadingVideos)
  useEffect(() => {
    setLoadingVideos(true)

    setColumns(getColumns("tiktok", global?.DEV, true));
    fetchBoard()
  }, []);
  useEffect(() => {
    // const token = localStorage.getItem("app-access-token");
    // let data = {
    //   token, boardId, page, limit: rowsPerPage
    // }
    setTimeout(() => {
      fetchVideos()
    }, 1000)
  }, [page, rowsPerPage, category, subCategory, searchTerm, selectedSubFolders])

  const handleViewChange = (view) => {
    setVideosView(view);
  };
  const requestSort = (key) => {
    if(key == 'reset' || key == 'Reset'){
      setVideoData(backupVideos)
      return;
    } 
    console.log("keyy is reqsoert: ", key);
    //console.log('request sort ' + key)
    let direction = "descending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      direction = "ascending";
    }
    setSortConfig({ key, direction });
  };
  const handleFilter = () => {
    if (filterWithDays) {
      if (numberOfDays) {
        const currentDate = dayjs(); // Current date using dayjs
        const start = currentDate.subtract(numberOfDays, "day"); // Subtract days using dayjs
        setStartDate(start);
        setEndDate(currentDate);
      } else {
        setStartDate(null);
        setEndDate(null);
      }
    } else {
      // Clear the dates when the checkbox is unchecked or no number of days entered
      setStartDate(null);
      setEndDate(null);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };
  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  const handleCheckBox = (filter) => {
    if (filter == 'reset') {
      selectedSubFolders.clear()
      selectedSubFolders.add(filter)
      setSelectedSubFolders(new Set(selectedSubFolders))

    } else {
      selectedSubFolders.delete('reset')
      const selected = selectedSubFolders.has(filter)

      if (selected) {
        selectedSubFolders.delete(filter)
      } else {
        selectedSubFolders.add(filter)
      }

      setSelectedSubFolders(new Set(selectedSubFolders))
    }

  }


  console.log('selected sub folders ', selectedSubFolders)

  useEffect(() => {
    let updatedData = [...videoData];
    //sort logic
    if (sortConfig !== null) {
      updatedData.sort((a, b) => {
        if (a.video[sortConfig.key] < b.video[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a.video[sortConfig.key] > b.video[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      // console.log({ sortedData: updatedData });
    } else {
      // console.log('sort config is null');
    }

    setVideoData([...updatedData]);
  }, [
    sortConfig
  ]);


  return (
    <div style={{ padding: '80px' }}>
      <div
        className="block bg-gray-800 rounded-xl shadow-sm"
        style={{ marginTop: "80px", padding: "20px 5px 20px 5px", }}
      >
        {loadingVideos ? (
          <div style={{ padding: "50px 0px" }}>
            <Loader />
          </div>
        ) : (
          <div>
            <div className="filter-top-bar">
              <div className="flex items-center justify-start">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    placeholder={"Search Videos"}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value || null)}
                    style={{
                      paddingRight: "50px",
                      background: "rgb(17 24 39 / 1)",
                    }}
                    className="css-5g0doo rounded-3xl bg-gray-800 transition ease-in-out border border-gray-800 focus:border-blue-600 focus:outline-none"
                  />
                  <div style={{ marginRight: "17px" }}>
                    <SearchIcon />
                  </div>
                </div>
              </div>
              <div className="right-side">
                {categories?.length &&
                  <div className="flex items-center justify-start ml-4">
                    <div className="relative">
                      <button
                        type="button"
                        onClick={toggleDropdown}
                        className=" dropdown-btn p-2 inline-block text-sm rounded-md bg-gray-800 transition ease-in-out border border-gray-700 focus:border-blue-600 focus:outline-none flex items-center whitespace-nowrap text-gray-200 py-2"
                      >
                        Folders
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="caret-down"
                          className="w-2 ml-2"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="currentColor"
                            d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                          ></path>
                        </svg>
                      </button>
                      {isOpen && (
                        <ul className="absolute drop-box text-base z-50 left-0 mt-2 list-none text-left rounded-lg shadow-lg bg-gray-800">
                          {/* Your list items go here */}
                          <li>
                            <label
                              className={`dropdown-item text-sm py-2 px-4 font-normal block w-full   text-gray-300   text-white focus:text-white focus:bg-gray-700 hover:bg-blue-600 "
                                }`}
                            >
                              <input
                                type="checkbox"
                                checked={"reset" === category?.name}
                                onChange={(e) => {
                                  setCategory({ name: "reset", subCategories: [] })
                                  toggleDropdown()
                                }}
                              />{" "}
                              All

                            </label>
                          </li>
                          {categories.map((filter, index) => (
                            <li key={index}>
                              <label
                                className={`dropdown-item text-sm py-2 px-4 font-normal block w-full text-gray-300 text-white focus:text-white focus:bg-gray-700 hover:bg-blue-600 ${filter.name === category?.name ? 'bg-blue-600' : ''
                                  }`}
                              >
                                <input
                                  type="checkbox"
                                  checked={filter.name === category?.name}
                                  onChange={() => { setCategory(filter); toggleDropdown() }}
                                />{' '}
                                {filter.name}
                              </label>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                }
                {subCategories?.length ?
                  <div className="flex items-center justify-start ml-4">
                    <div className="relative">
                      <button
                        type="button"
                        onClick={toggleDropdown1}
                        className="  dropdown-btn inline-block text-sm rounded-md bg-gray-800 transition ease-in-out border border-gray-700 focus:border-blue-600 focus:outline-none flex items-center whitespace-nowrap text-gray-200 py-1"
                      >
                        Subfolders
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="caret-down"
                          className="w-2 ml-2"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="currentColor"
                            d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                          ></path>
                        </svg>
                      </button>
                      {isOpen1 && (
                        <ul className="absolute drop-box text-base z-50 left-0 mt-2 list-none text-left rounded-lg shadow-lg bg-gray-800">
                          {/* Your list items go here */}
                          <li>
                            <label
                              className={`dropdown-item text-sm py-2 px-4 font-normal block w-full   text-gray-300  text-white focus:text-white focus:bg-gray-700 hover:bg-blue-600
                                }`}
                            >
                              <input
                                type="checkbox"
                                checked={selectedSubFolders.has('reset')}
                                onChange={(e) => {
                                  // setSubCategory("reset");
                                  // toggleDropdown1()
                                  handleCheckBox('reset')
                                }}
                              />{" "}
                              All

                            </label>
                          </li>
                          {subCategories.map((filter, index) => (
                            <li key={index}>
                              <label
                                className={`dropdown-item text-sm py-2 px-4 font-normal block w-full text-gray-300 text-white focus:text-white focus:bg-gray-700 hover:bg-blue-600 ${filter === subCategory ? 'bg-blue-600' : ''
                                  }`}
                              >
                                <input
                                  type="checkbox"
                                  checked={filter === subCategory || selectedSubFolders.has(filter)}
                                  onChange={() => { handleCheckBox(filter) }}
                                />{' '}
                                {filter}
                              </label>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div> : null
                }
                <div className="flex items-center justify-start ml-4">
                  <div className="flex justify-center">
                    <div
                      className="relative"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <button
                        // type="button"
                        // id="dropdownMenuButton3"
                        // data-bs-toggle="dropdown"
                        // aria-expanded="false"
                        style={{ paddingInline: "12px", paddingBlock: "7px" }}
                        className="dropdown-btn p-2 inline-block text-sm rounded-md bg-gray-800 transition ease-in-out border border-gray-700 focus:border-blue-600 focus:outline-none flex items-center whitespace-nowrap text-gray-200 py-2"
                        onClick={toggleDropdown2}
                      >
                        Sort Videos By
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="caret-down"
                          className="w-2 ml-2"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="currentColor"
                            d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                          ></path>
                        </svg>
                      </button>
                      {
                        isOpen2 && (
                          <ul
                            className="absolute drop-box text-base z-50 left-0 mt-2 list-none text-left rounded-lg shadow-lg bg-gray-800 dropdown-wrapper"
                          >
                            {sortFilters?.map((filter, index) => {
                              var selected = selectedSortFilters.has(filter.id);
                              return (
                                <li>
                                  <label
                                    className={`dropdown-item text-sm py-2 px-4 font-normal block w-full text-gray-300 text-white focus:text-white focus:bg-gray-700 hover:bg-blue-600 ${selected && "bg-blue-600"
                                      }`}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selected}
                                      onChange={() => {
                                        if (selected) {
                                          selectedSortFilters.delete(filter.id);
                                        } else {
                                          selectedSortFilters.clear();
                                          selectedSortFilters.add(filter.id);
                                        }
                                        setSelectedSortFilters(
                                          new Set(selectedSortFilters)
                                        );
                                        requestSort(filter.value);
                                        // filter.id === "5"
                                        //   ? setSortConfig(null)
                                        //   : requestSort(filter.value);
                                        toggleDropdown2()
                                      }}
                                    />{" "}
                                    {filter.name}{" "}

                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        )
                      }
                    </div>
                  </div>
                </div>

                {/* <div className="flex items-center justify-start ml-4 ">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="cursor-pointer"
                    data-bs-toggle={"modal"}
                    data-bs-target="#exampleModal1"
                  >
                    <div
                      style={{ paddingInline: "20px" }}
                      className="css-5g0doo text-sm rounded-md bg-gray-800 transition text-gray-200 ease-in-out border border-gray-700 focus:border-blue-600 focus:outline-none flex items-center"
                    >
                      <span>
                        {startDate
                          ? new Date(startDate)?.toLocaleDateString()
                          : "Start Date"}
                        <span className="text-gray-500"> - </span>
                        {endDate
                          ? new Date(endDate)?.toLocaleDateString()
                          : "End Date"}
                      </span>
                    </div>
                  </div>

                  {(startDate || endDate) && (
                    <span
                      onClick={() => {
                        setStartDate("");
                        setEndDate("");
                      }}
                      style={{ cursor: "pointer", marginLeft: "-3px" }}
                      className="text-gray-500 cursor-pointer ml-1 transition duration-200 transform hover:scale-150"
                    >
                      <AiOutlineCloseCircle />
                    </span>
                  )}
                </div>
              </div> */}
                <div className="flex items-center justify-start ml-4">
                  <div>
                    <button
                      className="small-btn"
                      id={videosView === "Grid" && "view-active"}
                      onClick={() => handleViewChange("Grid")}
                    >
                      <BiGrid size={25} />
                    </button>
                    <button
                      className="small-btn"
                      id={videosView === "List" && "view-active"}
                      onClick={() => handleViewChange("List")}
                    >
                      <FaList size={25} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="block bg-gray-800 rounded-xl shadow-sm "
              style={{ marginTop: "20px" }}
            >
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg pb-8">
                {videosView === "List" ? (
                  <table
                    className="w-full text-xs text-left"
                    style={{ tableLayout: "auto" }}
                  >
                    <thead className="text-xs uppercase">
                      <tr>
                        {columns.map((column, i) => (
                          <Header
                            key={i}
                            column={column}
                            i={i}
                            requestSort={requestSort}
                          // selectedVideos={selectedVideos}
                          // setSelectedVideos={setSelectedVideos}
                          // pageData={pageData}
                          />
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {videoData?.map((item, i) => (
                        <ListRow
                          key={i}
                          item={{ ...item?.video, platform: item.platform }}
                          i={i}
                          columns={columns}
                        // selectedVideos={selectedVideos}
                        // setSelectedVideos={setSelectedVideos}
                        // platform={platform}
                        />
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Container>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      {videoData?.map((item, i) => (
                        <Rows
                          key={i}
                          item={{ ...item?.video, platform: item?.platform }}
                          i={i}
                          // handleShow={handleShow}
                          columns={columns}
                        // selectedVideos={selectedVideos}
                        // setSelectedVideos={setSelectedVideos}
                        // platform={platform}
                        // setBoardVideo={setBoardVideo}
                        // boardVideo={boardVideo}
                        />
                      ))}
                    </Row>
                  </Container>
                )}

                <div className="flex flex-col items-center mt-10 mb-4">
                  <span className="text-sm text-gray-400">
                    Showing{" "}
                    <span className="font-semibold text-gray-300">
                    {reachedEnd ? 0 : page > 0 ? (page - 1) * rowsPerPage + 1 : 0}
                      {/* {(page - 1) * rowsPerPage || 1} */}
                    </span>{" "}
                    to{" "}
                    <span className="font-semibold text-gray-300">
                    {reachedEnd ? 0 : (page - 1) * rowsPerPage + pageData?.length}
                    </span>{" "}
                    out of {reachedEnd ? 0 : totalData} results
                  </span>
                  <div className="inline-flex mt-2 xs:mt-0">
                  <button
                    className={clsx(
                      "inline-flex items-center px-4 py-2 text-sm font-medium text-gray-300 rounded-l bg-gray-800 border-gray-700 hover:bg-gray-700",
                      page == 1
                        ? "bg-gray-700 hover:bg-gray-600 pointer-events-none"
                        : "bg-gray-900 hover:bg-gray-800"
                    )}
                    onClick={() => {
                      if (page > 1) setPage(page - 1);
                    }}
                  >
                    Newer
                  </button>
                  <button
                    className={clsx(
                      "inline-flex items-center px-4 py-2 text-sm font-medium text-gray-300 border-0 border-l border-gray-700 rounded-r bg-gray-800 border-gray-700 hover:bg-gray-700",
                      reachedEnd
                        ? "bg-gray-700 hover:bg-gray-600 pointer-events-none"
                        : "bg-gray-900 hover:bg-gray-800"
                    )}
                    onClick={() => setPage(page + 1)}
                  >
                    Older
                  </button>
                </div>
                  <div
                    className="flex justify-center items-center mt-4 "
                    style={{ marginLeft: "auto", marginRight: "20px" }}
                  >
                    <select
                      className="form-select form-select-sm appearance-none w-32 px-2 py-1 text-xs font-normal text-gray-400 bg-gray-800 bg-clip-padding bg-no-repeat border border-solid border-gray-700 rounded transition ease-in-out focus:text-gray-300 focus:bg-gray-800 focus:border-blue-600 focus:outline-none"
                      value={rowsPerPage}
                      onChange={(e) =>
                        setRowsPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="12">12 per page</option>
                      <option value="25">25 per page</option>
                      {/* <option value="20">20 per page</option> */}
                      <option value="50">50 per page</option>
                      <option value="100">100 per page</option>
                      {/* <option value="25">25 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option> */}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
              id="exampleModal1"
              tabIndex="-1"
              aria-labelledby="exampleModal1Label"
              aria-hidden="true"
            >
              <div className="modal-dialog relative w-auto pointer-events-none">
                <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                  <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                    <h5
                      className="text-xl font-medium leading-normal text-gray-800"
                      id="exampleModal1Label"
                    >
                      Select Date Range
                    </h5>
                    <button
                      type="button"
                      className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                      data-bs-dismiss="modal"
                      aria-label="Submit"
                    ></button>
                  </div>
                  <div className="modal-body relative p-4">
                    <div className="mb-4">
                      <div
                        className="flex mt-2 items-center"
                        style={{ flexDirection: "column" }}
                      >
                        <label className="flex items-center flex-row space-x-2">
                          <input
                            type="checkbox"
                            className="form-checkbox h-6 w-6 text-blue-600"
                            checked={filterWithDays}
                            onChange={() =>
                              setFilterWithDays(!filterWithDays)
                            }
                          />
                          <div
                            className="mt-4 flex flex-row"
                            style={{ marginTop: 10 }}
                          >
                            <label className="text-sm text-gray-600">
                              Show me videos from the last
                            </label>
                            <div
                              className="flex items-center space-x-2"
                              style={{ marginLeft: 5 }}
                            >
                              <input
                                type="number"
                                className="w-20 px-2 py-1 border rounded-md"
                                // placeholder="Enter days"
                                value={numberOfDays}
                                onChange={(e) =>
                                  setNumberOfDays(e.target.value)
                                }
                                style={{
                                  backgroundColor: "#e3e3e3",
                                  color: "black",
                                }}
                              />
                              <span className="text-gray-600">days</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>

                    <div className="flex justify-center items-center mb-8 text-slate-800">
                      <span style={{ width: "50px" }}> From </span>
                      <DatePicker
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                      />
                    </div>

                    <div className="flex justify-center items-center mb- text-slate-800">
                      <span style={{ width: "50px" }}> To </span>
                      <DatePicker
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                      />
                    </div>
                  </div>

                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                    <button
                      type="button"
                      className="px-6 py-2.5 bg-slate-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-slate-800 active:shadow-lg transition duration-150 ease-in-out"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        //HandleChangingRange(ranges[5])
                        handleFilter();
                      }}
                      type="button"
                      className="px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <VideoboardModel show={true}/> */}
      </div>
    </div>
  )
}

export default Videoboard