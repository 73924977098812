import "./App.scss";
import "tw-elements";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import lazy from "react-lazy-with-preload";
import React, { Suspense, useEffect, useState, useRef } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import useCurrentPathname from "./hooks/useCurrentPathname";
import { LocalizationProvider, nlNL } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticateAsyncThunk,
  userLogoutAsyncThunk,
} from "./store/authSlice";
import Pricing from "./pages/Pricing";
import Joinpage from "./pages/JoinPage";
import { NEXT_PUBLIC_PATH } from "./services/constant";
import Logout from "./pages/Logout";
import Videoboard from "./components/Videoboard";

import LayoutThree from './pages/LayoutThree'

const HomePage = lazy(() => import("./pages/HomePage"));
const JoinPage = lazy(() => import("./pages/JoinPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const DownloadPage = lazy(() => import("./pages/DownloadPage"));
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const ForgotPasswordPage = lazy(() => import("./pages/Forgot-password"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPassword"));
const AuthenticatePage = lazy(() => import("./pages/Authenticate"));
// const EmbedPage = lazy(() => import('./pages/EmbedPage'));
const userLocalStorage = localStorage.getItem("user");
let user;

if (userLocalStorage) {
  try {
    user = JSON.parse(userLocalStorage);
  } catch (error) {
    console.error("Parsing error:", error);
    // Handle the error, maybe set user to a default value
    user = null; // or some default object
  }
} else {
  // If there's nothing in local storage, set user to null or a default object
  user = null; // or some default object
}

// Continue with your code, using the 'user' variable


global.DEV = false;
if (process.env.NODE_ENV === "development") {
  console.log("Running in development mode");
  global.DEV = true;
} else if (process.env.NODE_ENV === "production") {
  console.log("Running in production mode");
} else {
  console.log("Unknown environment");
}

// global.SERVER_HOST = global.DEV
//   ? "http://localhost:8000"
//   : "https://api.tokbackup.com";

global.SERVER_HOST = process.env.REACT_APP_SERVER_HOST

// global.SCRAP_SERVER_HOST = global.DEV
//   ? "http://localhost:4000"
//   : "https://scrapper.tokbackup.com";


global.SCRAP_SERVER_HOST = process.env.REACT_APP_SCRAPING_SERVER_HOST;


function App() {
  useEffect(() => {
    const cookieValue = getCookieValue("tokens");
    if (cookieValue) {
      const tokensAndUser = JSON.parse(cookieValue);
      console.log("Tokens and User:", tokensAndUser);
    }
  }, []);
  const dispatch = useDispatch();

  const logout = async () => {
    await dispatch(userLogoutAsyncThunk());
  };
  window.addEventListener("message", (event) => {
    if (event.origin === NEXT_PUBLIC_PATH && event.data.type === "logout") {
      // Handle the logout request here (e.g., clear app-related data and perform logout)
      logout(); // You need to implement this function
    }
  });
  const getCookieValue = (cookieName) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const [name, value] = cookies[i].split("=");
      if (name === cookieName) {
        return decodeURIComponent(value); // Decode the value before parsing JSON
      }
    }
    return null;
  };

  const key = localStorage.getItem("key");

  const url = new URL(document.URL);
  var urlPath = url.pathname;
  urlPath = urlPath?.includes("admin")
    ? "admin"
    : urlPath.replace(/[^0-9]/g, "");

  const currentPathname = useCurrentPathname();

  useEffect(() => {
    const token = localStorage.getItem("app-access-token");
    if (token && token !== undefined && token !== null) {
      console.log(typeof token);
      console.log(token);
      dispatch(authenticateAsyncThunk());
    }
  }, [dispatch]);

  console.log('loged in user ', user)

  useEffect(() => {
    // Intercom Settings Script
    window.intercomSettings = user ? {
      api_base: "https://api-iam.intercom.io",
      app_id: "ikgd1nr5",
      name: user?.name, // Replace with the actual name from your React component state or props
      email: user?.email, // Replace with the actual email from your React component state or props
      created_at: user?.createdAt, // Replace with the actual signup date
    } : {
      api_base: "https://api-iam.intercom.io",
      app_id: "ikgd1nr5"
    };

    // Intercom Widget Script
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () { i.c(arguments); };
        i.q = [];
        i.c = function (args) { i.q.push(args); };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/ikgd1nr5';
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }, []);

  const PrivateRoute = ({ element: Element }) => {
    const token = localStorage.getItem("app-access-token");
    return token ? (
      <Element />
    ) : (
      <Navigate to="/login" replace state={{ from: "/" }} />
    );
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="App ">
          <BrowserRouter>
            <Suspense
              fallback={
                <div
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    zIndex: "10",
                  }}
                  className="loadingio-spinner-interwind-flyom1cz6sv"
                >
                  <div className="ldio-zxrz71mlja">
                    <div>
                      <div>
                        <div>
                          <div></div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              {currentPathname !== "/embed" && <Header />}

              <div className="pages">
                <Routes>
                  <Route exact path="/" element={<HomePage />} />
                  <Route
                    exact
                    path="/embed"
                    element={<HomePage isEmbedded={true} />}
                  />
                  <Route
                    exact
                    path="/username/:platformName/:username"
                    element={<HomePage />}
                  />
                  <Route exact path="/thank-you" element={<HomePage />} />
                  <Route
                    exact
                    path="/forgot-password"
                    element={<ForgotPasswordPage />}
                  />
                  <Route exact path="/login" element={<LoginPage />} />
                  <Route exact path="/join" element={<Joinpage />} />
                  <Route
                    exact
                    path="/reset-password"
                    element={<ResetPasswordPage />}
                  />
                  {/* <Route exact path="/download/:platformName/:username" element={<DownloadPage />} /> */}
                  <Route
                    path="/download/:platformName/:username"
                    element={<PrivateRoute element={DownloadPage} />}
                  />
                  <Route
                    path="/pricing"
                    element={<PrivateRoute element={Pricing} />}
                  />
                  <Route exact path="/dashboard" element={<DashboardPage />} />
                  <Route exact path="/logout" element={<Logout />} />
                  <Route exact path="/board/:boardId/:category" element={<Videoboard />} />
                  <Route exact path="/board/:boardId/video/:videoid" element={<Videoboard />} />
                  <Route exact path="/board/:boardId/:category/:subcategory" element={<Videoboard />} />
                  <Route exact path="/board/:boardId/:category/:subcategory/:videoid" element={<Videoboard />} />
                  <Route exact path="/videoboard/:boardId" element={<Videoboard />} />
                  <Route
                    exact
                    path="/authenticate"
                    element={<PrivateRoute element={AuthenticatePage} />}
                  />
                  <Route exact path="/layout-three" element={<LayoutThree />} />
                  {/* <Route exact path="/embed" element={<EmbedPage />} /> */}
                </Routes>
              </div>
              {currentPathname !== "/embed" && <Footer />}
            </Suspense>
          </BrowserRouter>
        </div>
      </LocalizationProvider>
    </>
  );
}

export default App;
