import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { auth, db } from "../firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { resolvePath, useNavigate } from "react-router-dom";
import LoggedInOrNot from "../utilities/LoggedInOrNot";
import useAuth from "../hooks/useAuth";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { userLoginAsyncThunk } from "../store/authSlice";
import { toast } from "react-toastify";
import { BiError } from "react-icons/bi";

import axios from 'axios';
import { APIurls, basePath } from "../services/constant";

import { GoogleLogin } from '@react-oauth/google';

import { googleLogout, useGoogleLogin } from '@react-oauth/google';



function LoginModal({ joinModalOpenRef, loginModalOpenRef }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [errorMessage, setErrorMessage] = useState([]);

  const [googleUser, setGoogleUser] = useState([]);
  const [profile, setProfile] = useState(null);

  console.log('user with google ', setGoogleUser)
  console.log('uer profile with google ', profile)

  // useEffect(
  //   () => {
  //     if (user) {
  //       axios
  //         .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user?.access_token}`, {
  //           headers: {
  //             Authorization: `Bearer ${user.access_token}`,
  //             Accept: 'application/json'
  //           }
  //         })
  //         .then((res) => {
  //           setProfile(res.data);
  //         })
  //         .catch((err) => console.log(err));
  //     }
  //   },
  //   [user]
  // );

  const handleRegister = async (data) => {
    try {
      if (data) {
        const registrationResponse = await axios.post(`${basePath}/auth/register-with-google`, { googleUserDetails: data });
        const registeredUser = registrationResponse.data;

        if (registeredUser?.error) {
          toast.error(registeredUser?.message)
          return;
        }

        console.log('response from node ', registeredUser)
        if (registeredUser) {
          setProfile(registeredUser?.user);
          localStorage.setItem(
            "app-access-token",
            registeredUser?.tokens?.access.token
          );
          localStorage.setItem(
            "app-access",
            JSON.stringify(registeredUser?.tokens?.access)
          );
          localStorage.setItem(
            "app-refresh",
            JSON.stringify(registeredUser.tokens?.refresh)
          );
          localStorage.setItem(
            "app-refresh-token",
            registeredUser?.tokens?.refresh?.token
          );
          localStorage.setItem("user", JSON.stringify(registeredUser?.user));
        }
      }
      toast.success('LoggedIn Successfully!')
      window.location.reload()
    } catch (error) {
      console.error('Error registering user:', error);
    }
  };

  useEffect(() => {
    const registerUser = async () => {
      try {
        if (googleUser) {
          const res = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser?.access_token}`, {
            headers: {
              Authorization: `Bearer ${googleUser.access_token}`,
              Accept: 'application/json'
            }
          });

          // Send the profile data to your server for registration
          if (res.data) {
            handleRegister(res.data)
          }

          // Set the profile state with the registered user
        }
      } catch (error) {
        console.error('Error getting user data:', error);
      }
    };

    registerUser();
  }, [googleUser]);


  const [data, setData] = useState({ errorMessage: "", isLoading: false });

  // const client_id = 'Iv1.4eb936cbcbb8a53a';
  // const redirect_uri = 'http://localhost:3000'

  const client_id = process.env.REACT_APP_CLIENT_ID;
  const redirect_uri = process.env.REACT_APP_REDIRECT_URL


  useEffect(() => {
    console.log('use effect calling')
    // After requesting Github access, Github redirects back to your app with a code parameter
    const url = window.location.href;
    const hasCode = url.includes("?code=");

    // If Github API returns the code parameter
    if (hasCode) {
      const newUrl = url.split("?code=");
      console.log('new Url ', newUrl)
      window.history.pushState({}, null, newUrl[0]);
      setData({ ...data, isLoading: true });

      const requestData = {
        code: newUrl[1]
      };

      const proxy_url = `${basePath}/auth/login-with-github`;

      // // Use code parameter and other parameters to make POST request to proxy_server
      // fetch(proxy_url, {
      //   method: "POST",
      //   body: JSON.stringify(requestData)
      // })
      //   .then(response => 
      //     // response.json()
      //     console.log('ata recieved ', response.json())
      //     )
      //   // .then(data => {
      //   //   console.log('data recieved ', data)
      //   // })
      //   .catch(error => {
      //     setData({
      //       isLoading: false,
      //       errorMessage: "Sorry! Login failed"
      //     });
      //   });
      axios.post(proxy_url, requestData)
        .then(response => {
          // Handle response data
          const registeredUser = response.data
          console.log('Data received:', response.data);
          if (registeredUser) {
            setProfile(registeredUser?.user);
            localStorage.setItem(
              "app-access-token",
              registeredUser?.tokens?.access.token
            );
            localStorage.setItem(
              "app-access",
              JSON.stringify(registeredUser?.tokens?.access)
            );
            localStorage.setItem(
              "app-refresh",
              JSON.stringify(registeredUser.tokens?.refresh)
            );
            localStorage.setItem(
              "app-refresh-token",
              registeredUser?.tokens?.refresh?.token
            );
            localStorage.setItem("user", JSON.stringify(registeredUser?.user));
            window.location.reload()
          }
        })
        .catch(error => {
          // Handle error
          console.error('Error occurred:', error);
          if(error.response){
            toast.error(error?.response?.data?.message)
          }
          // toast.error(error.message)
          setData({
            isLoading: false,
            errorMessage: "Sorry! Login failed"
          });
        });
    }
  }, []);



  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  const popupModalRef = useRef();

  let schema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const [loginLoading, setLoginLoading] = useState(false);

  // const handleSubmit = async (values, { setSubmitting }) => {
  //   try {
  //     await schema.validate(values);
  //     // Validation passed, dispatch login action
  //     const { payload } = await dispatch(
  //       userLoginAsyncThunk({
  //         user: values,
  //         cb: (d) => {
  //           setSubmitting(false);
  //           toast.success("You have Logged In!", {
  //             autoClose: 2000,
  //           });
  //           loginModalOpenRef?.current?.click();
  //         },
  //         navigate,
  //       })
  //     );

  //     const errorMessage =
  //       payload?.response?.data?.code !== 201
  //         ? payload?.response?.data?.message
  //         : null;
  //     if (errorMessage) {
  //       toast.error(errorMessage);
  //     }
  //   } catch (err) {
  //     // Validation failed, set form errors
  //     setSubmitting(false);
  //   }
  // };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await schema.validate(values);

      const response = await axios.post(`${basePath}/${APIurls.login}`, values);
      console.log('response  ', response)
      if (response && response.status === 200) {
        const tokens = response.data.tokens; // Adjust this based on your actual response structure
        localStorage.setItem("app-access-token", tokens?.access?.token);
        localStorage.setItem("app-access", JSON.stringify(tokens?.access));
        localStorage.setItem("app-refresh", JSON.stringify(tokens?.refresh));
        localStorage.setItem("app-refresh-token", tokens?.refresh?.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        setSubmitting(false);
        toast.success("You have Logged In!", {
          autoClose: 2000,
        });
        navigate('/')
        window.location.reload()

        loginModalOpenRef?.current?.click();
      } else {
        setSubmitting(false);
        const errorMessage = response?.data?.error || "Login failed. Please try again later.";
        toast.error(errorMessage);
      }
    } catch (err) {
      setSubmitting(false);
      const errorMessage = err?.response?.data?.message || 'Unknown error';
      toast.error(errorMessage);
    }
  };

  const loginSuccess = (response) => {
    console.log(response);
  };
  const loginError = (error) => {
    console.log(error);
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary hidden"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal3"
        ref={loginModalOpenRef}
      >
        OPEN LOGIN MODAL{" "}
      </button>

      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="exampleModal3"
        tabIndex="-1"
        aria-labelledby="exampleModal3Label"
        aria-hidden="true"
      >
        <div className="modal-dialog relative w-auto pointer-events-none modal-dialog-centered">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-gray-900 bg-clip-padding rounded-md outline-none text-current p-10">
            <div>
              <h1
                className="text-2xl font-semibold text-gray-800 mb-6"
                style={{ color: "#ccdae7" }}
              >
                Login
              </h1>

              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={schema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-group mb-6">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                        style={{ width: "100%", color: "#ccdae7" }}
                        className="css-5g0doo bg-gray-800 text-white p-2 text-xs p-2   rounded-2xl     transition ease-in-out border border-gray-700    focus:text-gray-700  focus:border-blue-600 focus:outline-none"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="text-red-500 text-xs text-left mt-1"

                      />
                    </div>

                    <div className="form-group mb-6">
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        style={{ width: "100%", color: "#ccdae7" }}
                        className="css-5g0doo bg-gray-800 text-white p-2 text-xs p-2   rounded-2xl     transition ease-in-out border border-gray-700    focus:text-gray-700  focus:border-blue-600 focus:outline-none"
                      />
                      <ErrorMessage
                        name="password"
                        component="p"
                        className="text-red-500 text-xs text-left mt-1"

                      />
                    </div>

                    <button
                      type="submit"
                      style={{
                        opacity: isSubmitting ? "0.5" : "1",
                        width: "200px",
                        color: "#ccdae7",
                      }}
                      className=" w-full mb-6  py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      {isSubmitting ? "Logging in..." : "Log In"}
                    </button>
                  </Form>
                )}
              </Formik>

              {/* <button onClick={() => login()}>Continue with Google 🚀 </button>

              <br />

              <a
                className="login-link"
                href={`https://github.com/login/oauth/authorize?scope=user&client_id=${client_id}&redirect_uri=${redirect_uri}`}
                onClick={() => {
                  setData({ ...data, errorMessage: "" });
                }}
              >
                <span>Continue with GitHub</span>
              </a> */}


              <p className="text-gray-600 mt-3 text-center text-sm">
                Forgot password?
                <a href="" onClick={() => navigate("/forgot-password")}>
                  {" "}
                  Reset
                </a>
              </p>
              <p className="text-gray-600 mt-3 text-center text-sm">
                Don't Have an account?{" "}
                <a
                  href="#!"
                  style={{ color: "#818995" }}
                  className="text-blue-600 hover:text-blue-400 focus:text-blue-700 transition duration-200 ease-in-out text-sm"
                  //dismiss modal
                  data-bs-dismiss="modal"
                  //dissmiss example modal1
                  data-bs-target="#exampleModal3"
                  onClick={() => {
                    joinModalOpenRef.current.click();
                  }}
                >
                  Create Account
                </a>
              </p>
              <p className="text-gray-600 mt-3 text-center text-sm">
                Already have a license key?{" "}
                <button
                  style={{ color: "#818995" }}
                  className="text-blue-600 hover:text-blue-400 focus:text-blue-700 transition duration-200 ease-in-out text-sm"
                  //dismiss modal
                  data-bs-dismiss="modal"
                  //dissmiss example modal1
                  data-bs-target="#popupModel"
                  onClick={() => {
                    popupModalRef.current.click();
                  }}
                >
                  click here
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary hidden "
        data-bs-toggle="modal"
        data-bs-target="#popupModel"
        ref={popupModalRef}
      >
        OPEN MODAL{" "}
      </button>
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="popupModel"
        tabIndex="-1"
        aria-labelledby="exampleModal2Label"
        aria-hidden="true"
      >
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-gray-900 bg-clip-padding rounded-md outline-none text-current p-10">
            <div style={{ color: "white", fontWeight: "400" }}>
              If you already have a license key, you can easily log in using
              your email and license key as your password. If you encounter any
              issues while logging in, don't worry! Our support team is here to
              assist you. Please reach out to us at{" "}
              <a href="mailto:support@tokbackup.com">support@tokbackup.com</a>{" "}
              for prompt assistance.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
