const GetTimeAgo = (date) => {
    if (!date instanceof Date || isNaN(date)) {
      return "Invalid date";
    }
  
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;
  
    if (secondsPast < 60) {
      return parseInt(secondsPast) + ' seconds ago';
    }
  
    if (secondsPast < 3600) {
      return parseInt(secondsPast / 60) + ' minutes ago';
    }
  
    if (secondsPast < 86400) {
      return parseInt(secondsPast / 3600) + ' hours ago';
    }
  
    const daysPast = Math.floor(secondsPast / 86400);
  
    if (daysPast < 30) {
      if (daysPast === 1) {
        return '1 day ago';
      }
      return daysPast + ' days ago';
    }
  
    const monthsPast = Math.floor(daysPast / 30);
  
    if (monthsPast < 12) {
      if (monthsPast === 1) {
        return '1 month ago';
      }
      return monthsPast + ' months ago';
    }
  
    const yearsPast = Math.floor(monthsPast / 12);
  
    if (yearsPast === 1) {
      return '1 year ago';
    }
    return yearsPast + ' years ago';
  };
  
  export default GetTimeAgo;
  