import React from "react";

export default function Loader() {
  return (
    <div className="" style={{ width: "100%", margin: "auto", height: 100 }}>
      <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-45%)",
          zIndex: "10",
          marginTop: "35px",
        }}
        className="loadingio-spinner-interwind-flyom1cz6sv"
      >
        <div className="ldio-zxrz71mlja" style={{ marginTop: 25 }}>
          <div>
            <div>
              <div>
                <div></div>
              </div>
            </div>
            <div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="text-xl text-center text-white">
        We're getting your videos ready. This won't take long.
      </h3>
      {/* <span className="dot">.</span>
      <span className="dot">.</span>
      <span className="dot">.</span> */}
    </div>
  );
}
