const FetchUserData = async (userUUID) => {

    try {
        const response = await fetch(global.SERVER_HOST + '/api/appuser/uuid/' + userUUID, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        const data = await response.json();
        return data

    } catch (error) {
        console.error('error fetching golfer data : ' + error.message);
      //  console.log('logging out ')
    //    localStorage.removeItem('isUserLoggedIn');
      //  window.location.reload();

    }
}


    export default FetchUserData;   