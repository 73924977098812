import React, { useEffect, useState, useRef } from "react";
import JoinModal from "./JoinModal";
import LoginModal from "./LoginModal";
import useAuth from "../hooks/useAuth";
import { signOut } from "firebase/auth";

import { auth } from "../firebase-config";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutAsyncThunk } from "../store/authSlice";
import { NEXT_PUBLIC_PATH, app_url } from "../services/constant";
import { useNavigate } from "react-router-dom";
import { ApiRequests } from "../services/ApiRequests";

function Header() {
  const navigate = useNavigate();
  const [board, setBoard] = useState()
  const { user, isLoggedIn } = useSelector((s) => s.auth);
  console.log("user", user)
  const dispatch = useDispatch();
  const loading = false;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [disableLogoutButton, setDisableLogoutButton] = useState(false);
  const logout = async () => {
    setDisableLogoutButton(true);
    await dispatch(userLogoutAsyncThunk());
    setDisableLogoutButton(false);
  };

  const fetchBoard = async () => {
    try {
      const token = localStorage.getItem("app-access-token");
      const response = await ApiRequests.getUserBoard(token)
      if (response.status === 200) {
        setBoard(response.data)
      } else {
        setBoard()
      }
    } catch (error) {
      console.log('error fetching board ', error)
    }
  }

  console.log("disable login ::", disableLogoutButton);

  useEffect(() => {
    const navbarShadow = document.querySelector(".navbar-shadow2");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        navbarShadow.style.backgroundColor = "rgba(13, 17, 23, 0.88)";
      } else {
        navbarShadow.style.backgroundColor = "rgb(17 24 39 / 1)";
      }
    });
    fetchBoard()
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    const target = event.target;

    if (
      isMenuOpen &&
      !target.closest(".hamburger-menu") &&
      !target.closest(".navbar-nav-menu")
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMenuOpen]);

  const joinModalOpenRef = useRef(null);
  const loginModalOpenRef = useRef(null);

  return (
    <>
      <JoinModal
        joinModalOpenRef={joinModalOpenRef}
        loginModalOpenRef={loginModalOpenRef}
      />
      <LoginModal
        joinModalOpenRef={joinModalOpenRef}
        loginModalOpenRef={loginModalOpenRef}
      />

      <div
        className="navbar-shadow2 snipcss-4e4ON style-MAxil"
        id="style-MAxil"
      >
        <div className="w-layout-grid navbar-list snipcss-XqigK">
          <div className="navbar-logo-navlinks">
            <a
              aria-current="page"
              className="brand w-nav-brand w--current"
              cursor-pointer
              aria-label="home"
              href={app_url}
              style={{ width: "130px" }}
            >
              <img
                style={{ width: "max-content" }}
                src="https://uploads-ssl.webflow.com/6418b3596d3d92089dfdd6f5/642b64f5e724a0b23467db25_tokbackup%401x.png"
                loading="lazy"
                alt=""
                className="image-contain"
              />
            </a>
            <nav
              role="navigation"
              style={{ padding: "0" }}
              className={`navbar-nav-menu w-nav-menu ${isMenuOpen ? "menu-open" : ""
                }`}
            >
              <a
                // href={'https://tokbackup.com/pricing'}
                target="_blank"
                onClick={() => navigate("/pricing")}
                className="nav-link _1st w-nav-link cursor-pointer hover:text-teal-500"
              >
                Pricing
              </a>
              <a
                href="https://help.tokaudit.io/"
                target="_blank"
                className="nav-link _1st w-nav-link cursor-pointer hover:text-teal-500"
              >
                Support
              </a>
              <a
                href="https://www.youtube.com/@TokAudit"
                target="_blank"
                className="nav-link _1st w-nav-link cursor-pointer hover:text-teal-500"
              >
                Training
              </a>
              {isLoggedIn && (
                <a
                  target="_blank"
                  // href={`${process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://portal.tokbackup.com"}/home`}
                  className="nav-link _1st w-nav-link cursor-pointer hover:text-teal-500"
                  onClick={() => navigate("/authenticate")}
                >
                  Dashboard
                </a>
              )}
              {/* {isLoggedIn && board && (
                <a
                  target="_blank"
                  className="nav-link _1st w-nav-link cursor-pointer hover:text-teal-500"
                  onClick={() => navigate("/videoboard/" + board.id)}
                >
                  My Board
                </a>

              )} */}
              {!isLoggedIn && (
                <a
                  className="nav-link _1st w-nav-link cursor-pointer hover:text-teal-500  hide_desktop"
                  onClick={() => {
                    loginModalOpenRef?.current?.click();
                  }}
                  style={{
                    opacity: loading ? 0.5 : 1,
                    pointerEvents: loading ? "none" : "all",
                  }}
                >
                  Login
                </a>
              )}
              {!isLoggedIn && (
                <a
                  className="nav-link _1st w-nav-link cursor-pointer hover:text-teal-500  hide_desktop"
                  onClick={() => {
                    joinModalOpenRef?.current?.click();
                  }}
                  style={{
                    opacity: loading ? 0.5 : 1,
                    pointerEvents: loading ? "none" : "all",
                  }}
                >
                  Join
                </a>
              )}
              {isLoggedIn && (
                <button
                  className="nav-link _1st w-nav-link cursor-pointer hover:text-teal-500  hide_desktop"
                  onClick={() => {
                    logout();
                  }}
                  // style={{
                  //   opacity: disableLogoutButton ? 0.5 : 1,
                  //   pointerEvents: disableLogoutButton ? "none" : "all",
                  // }}
                  disabled={disableLogoutButton}
                >
                  Logout
                </button>
              )}

              <a
                // href={'https://www.tokbackup.com/pricing'}
                onClick={() => navigate("/pricing")}
                target="_blank"
                style={{ width: "100%", textAlign: "right" }}
                className="nav-link signup-btn-hide _1st w-nav-link cursor-pointer bg-rose-500  w-full  hover:text-teal-500 mobile-signup"
              >
                Signup Today!
              </a>
            </nav>
          </div>
          <div className="right-side">
            <button
              onClick={toggleMenu}
              className={`hamburger-menu ${isMenuOpen ? "open" : ""}`}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            {!isLoggedIn && (
              <a
                target="_blank"
                // href={"https://www.tokbackup.com/pricing"}
                onClick={() => navigate('/join')}
                className="button-animated small hide_mobile w-inline-block"
              >
                <div className="button-label cursor-pointer ">Signup Today!</div>
              </a>
            )}
            {isLoggedIn && (
              <a
                onClick={() => {
                  logout();
                }}
                className="button-animated small hide_mobile w-inline-block  "
                style={{

                  backgroundColor: "transparent",
                  opacity: loading ? 0.5 : 1,
                  pointerEvents: loading ? "none" : "all",
                }}
              >
                <div
                  className="button-label cursor-pointer nav-link _1st w-nav-link cursor-pointer hover:text-teal-500 "
                  style={{ padding: "5px", fontWeight: "200" }}
                >
                  Logout <i class="fa-solid fa-right-from-bracket ml-2"></i>
                </div>
              </a>
            )}
            {!isLoggedIn && (
              <a
                onClick={() => {
                  loginModalOpenRef?.current?.click();
                }}
                className="button-animated small hide_mobile w-inline-block  "
                style={{
                  backgroundColor: "transparent",
                  opacity: loading ? 0.5 : 1,
                  pointerEvents: loading ? "none" : "all",
                }}
              >
                <div
                  className="button-label cursor-pointer nav-link _1st w-nav-link cursor-pointer hover:text-teal-500 "
                  style={{ padding: "5px", fontWeight: "200" }}
                >
                  Login
                </div>
              </a>
            )}
            {!isLoggedIn && (
              <a
                onClick={() => {
                  joinModalOpenRef?.current?.click();
                }}
                className="button-animated small hide_mobile w-inline-block"
                style={{
                  backgroundColor: "transparent",
                  opacity: loading ? 0.5 : 1,
                  pointerEvents: loading ? "none" : "all",
                }}
              >
                <div
                  className="button-label cursor-pointer nav-link _1st w-nav-link cursor-pointer hover:text-teal-500 "
                  style={{ padding: "5px", fontWeight: "200" }}
                >
                  Join
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
