import { Button } from "bootstrap";
import React, { useEffect, useState } from "react";
import Creatable from "react-select/creatable";

const DynamicSelect = ({
  handleInputChange,
  options,
  type,
  inputValue,
  handleChange,
  heading,
  value,
  handleCheckboxChange,
  selectedValues,
  setSelectedValues
}) => {
  const [addNew, setNew] = useState(false);
  // const [selectedValues, setSelectedValues] = useState([]);

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     background: state.isFocused ? "black" : "white", // Set focused color to black
  //     borderColor: state.isFocused ? "black" : "#3B3B3B", // Set focused border color to black
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     color: state.isSelected ? "black" : "#3B3B3B", // Set selected color to black
  //     background: state.isSelected ? "#3B3B3B" : "white", // Set selected background color to black
  //   }),
  // };
  useEffect(() => {
    setNew(options.length === 0 ? true : false);
  }, [options]);


  // const handleCheckboxChange = (filter) => {
  //   const isSelected = selectedValues.includes(filter);

  //   if (isSelected) {
  //     setSelectedValues(selectedValues.filter((value) => value !== filter));
  //   } else {
  //     setSelectedValues([...selectedValues, filter]);
  //   }
  // };

  return (

    <div style={{ flexDirection: "column", borderRadius: "6px" }}>
      <h2 className={`text-lg`}>{heading}</h2>
      {addNew ? (
        <>

          <input
            style={{
              width: "100%",
              height: "50px",
              padding: "5px 15px 5px 15px",
              borderRadius: "6px",
              marginBlock: "5px",
            }}
            type="text"
            id="input"
            placeholder={`Create New ${type} or select from list`}
            value={inputValue}
            onChange={handleInputChange}
          />
          <div className="m-cancel-btn">
            <button onClick={() => setNew(false)}>Cancel</button>
          </div>
        </>
      ) : (

        <ul className="text-base z-50 left-0 mt-2 list-none text-left  shadow-lg bg-gray-600">
          {options.map((filter, index) => (
            <li key={index}>
              <label
                className={`dropdown-item text-sm py-2 px-4 font-normal block w-full text-gray-300 bg-gray-700 text-white focus:text-white focus:bg-gray-600 hover:bg-blue-600 flex gap-3 items-center`}
              >
                <input
                  type="checkbox"
                  checked={heading !== 'Sub Folder' ? (filter?.name
                    ? filter?.name == inputValue
                    : filter == inputValue) : selectedValues.includes(filter)}
                  onChange={() => {
                    heading == 'Sub Folder' ? handleCheckboxChange(filter) : handleChange(filter);
                  }}
                />
                <i className="fa-solid fa-folder"></i>
                {filter?.name || filter}
              </label>
            </li>
          ))}

          <div className="create-new">
            <button onClick={() => setNew(true)}>Create New {heading}</button>
            <i class="fa-solid fa-plus"></i>
          </div>
        </ul>
      )}
      {/* <Creatable
        isClearable
        onChange={handleChange}
        onCreateOption={(input) => handleChange({ value: input, label: input })}
        options={options.map((option) => ({
          value: option?.name ? JSON.stringify(option) : option,
          label: option?.name || option,
        }))}
        value={options.find((option) => option.label === inputValue)}
        styles={customStyles}
      /> */}
    </div>
  );
};

export default DynamicSelect;
