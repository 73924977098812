import React, {useState} from 'react';
import '../DownloadPage.scss'

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";

import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';

import TotalDetailCards from '../components/TotalDetailCards'

import LogoImg from '../assets/downloadPageImgs/logo-wrapper.png'
import youtubeShortsIcon from '../assets/downloadPageImgs/youtube-shorts-icon.png'
import avatorIcon from '../assets/downloadPageImgs/avator icon.png'
import startyellowIcon from '../assets/downloadPageImgs/start-yellow-icon.png'
import personGreenIcon from '../assets/downloadPageImgs/person-green-icon.png'
import bookmarkPurpleIcon from '../assets/downloadPageImgs/bookmark-purple-icon.png'
import cameraBlueIcon from '../assets/downloadPageImgs/camera-blue-icon.png'
import downloadZip from '../assets/downloadPageImgs/download-reels-zip-icon.png'
import downloadCSV from '../assets/downloadPageImgs/download-csv.png'
import filterPinkIcon from '../assets/downloadPageImgs/filter-pink-icon.png'
import dateIcon from '../assets/downloadPageImgs/date-icon.png'
import tikTokPost1 from '../assets/downloadPageImgs/tiktok-post-1.png'
import tikTokPost2 from '../assets/downloadPageImgs/tiktok-post-2.png'
import tikTokPost3 from '../assets/downloadPageImgs/tiktok-post-3.png'
import tikTokPost4 from '../assets/downloadPageImgs/tiktok-post -4.png'
import tikTokPost5 from '../assets/downloadPageImgs/tiktok-post -5.png'
import tikTokPost6 from '../assets/downloadPageImgs/tiktok-post-6.png'
import tikTokPost7 from '../assets/downloadPageImgs/tiktok-post-7.png'
import tikTokPost8 from '../assets/downloadPageImgs/tiktok-post-8.png'
import tikTokPost9 from '../assets/downloadPageImgs/tiktok-post-9.png'
import tikTokPost10 from '../assets/downloadPageImgs/tiktok-post-10.png'
import calendarWhiteIcon from '../assets/downloadPageImgs/calendar-icon-white.png'
import largeGraph from '../assets/downloadPageImgs/large-graph.png'
import image3D from '../assets/downloadPageImgs/3d-img.png'

export default function LayoutThree() {


    const [activeTab, setActiveTab] = useState('basicData');
    const [activeChartTab, setActiveChartTab] = useState('accountGrowth');
    const [activeVieoTab, setActiveVideoTab] = useState('profile');
    const [volume, setVolume] = useState(50);

    const handleTabClick = (tab, event) => {
        event.preventDefault(); // Prevent the default behavior of the anchor tag
        setActiveTab(tab);
    };

    const videoTabClick = (tab, event) => {
        event.preventDefault(); // Prevent the default behavior of the anchor tag
        setActiveVideoTab(tab);
    };
    const chartTabClick = (tab, event) => {
        event.preventDefault();
        setActiveChartTab(tab);
    }
    const handleVolumeChange = (e) => {
        setVolume(e.target.value);
        // You can perform any other volume-related actions here
    };

    return (
        <div className='download-page'>
            <section id='search-section'>
                <div className='container'>
                    <div className='search-bar-wrapper'>
                        <form>
                            <div className='form-group'>
                                <div className='select-tag'>
                                    <img src={youtubeShortsIcon} alt='youtubeShortsIcon'
                                         className='youtube-shorts-icon'/>
                                    <Form.Select aria-label="Default select example">
                                        {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                        <option></option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                    </Form.Select>
                                </div>
                                <input type='text' placeholder='@ Username'/>
                                <button className='search-btn'>Search handle</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section id='description-detail-section' className='layout-three'>
                <div className='container'>
                    <div className='description-main-wrapper '>
                        <div className='row layout-three'>
                            <div className='col-xl-3 col-lg-4 col-md-4'>
                                <div className='content-wrapper'>
                                    <div className='logo-main-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={avatorIcon} alt='avatorIcon' className='avatorIcon'/>
                                        </div>
                                        <div className='text-wrapper'>
                                            <p className='large'>@michaelsanchez</p>
                                            <p>USA, Carlifonia</p>
                                        </div>
                                    </div>
                                    <p>Michael Sanchez - TikTok <br></br>
                                        → TikTok Marketing & Growth <br/>
                                        Strategies since 2018→ 80billion+ <br/>
                                        views on TikTok last 3 years <br/>
                                        (yes a B)👇 Currently building… <br/>
                                        www.michael.consulting + 1 <br/>
                                    </p>
                                </div>
                            </div>
                            <div className='col-xl-4 col-lg-4 col-md-4 '>
                                <div className='detail-wrapper'>
                                    <div className='detail'>
                                        <div className='icon yellow'>
                                            <img src={startyellowIcon} alt='startyellowIcon'/>
                                        </div>
                                        <div className='text'>
                                            <h3>71.2K</h3>
                                            <p>Followers</p>
                                        </div>
                                    </div>
                                    <div className='detail'>
                                        <div className='icon green'>
                                            <img src={personGreenIcon} alt='personGreenIcon'/>
                                        </div>
                                        <div className='text'>
                                            <h3>1.2K</h3>
                                            <p>Following</p>
                                        </div>
                                    </div>
                                    <div className='detail'>
                                        <div className='icon blue'>
                                            <img src={cameraBlueIcon} alt='cameraBlueIcon'/>
                                        </div>
                                        <div className='text'>
                                            <h3>236</h3>
                                            <p>Videos</p>
                                        </div>
                                    </div>
                                    <div className='detail'>
                                        <div className='icon purple'>
                                            <img src={bookmarkPurpleIcon} alt='bookmarkPurpleIcon'/>
                                        </div>
                                        <div className='text'>
                                            <h3>84</h3>
                                            <p>Bookmarks</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-5 col-lg-4 col-md-4'>
                                <div className='image-wrapper-3d'>
                                    <img src={image3D} alt='image3D' className='image3D'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='video-charts-tabs-section'>
                <div className='container'>
                    <div className='main-tabs'>
                        <ul className='nav-tabs'>
                            <li><a href='#' onClick={(e) => handleTabClick('basicData', e)}
                                   className={activeTab === 'basicData' ? 'active' : ''}>Basic data</a></li>
                            <li><a href='#' onClick={(e) => handleTabClick('video', e)}
                                   className={activeTab === 'video' ? 'active' : ''}>Videos</a></li>
                            <li><a href='#' onClick={(e) => handleTabClick('analytics', e)}
                                   className={activeTab === 'analytics' ? 'active' : ''}>Analytics</a></li>
                            <li><a href='#' onClick={(e) => handleTabClick('chat', e)}
                                   className={activeTab === 'chat' ? 'active' : ''}>Charts</a></li>
                        </ul>
                    </div>

                    {activeTab === 'basicData' && (
                        <div id='download-btns-section'>
                            <div className='container'>
                                <div className='btns-wrapper'>
                                    <div className='row'>
                                        <div className='col-md-6 text-end'>
                                            <a href='#' className='download-btn'>Download Reels ZIP <img
                                                src={downloadZip} alt='downloadZip'/></a>
                                        </div>
                                        <div className='col-md-6'>
                                            <a href='#' className='download-btn'>Download Reporting Data CSV <img
                                                src={downloadCSV} alt='downloadCSV'/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 'video' && (
                        <div className='main-content-wrapper'>
                            <div className='video-tab-wrapper'>
                                <div className='title-bar-wrapper'>
                                    <div className='row'>
                                        <div className='col-lg-7 align-self-center col-md-6 text-start'>
                                            <ul className='nav-tab-wrapper'>
                                                <li><a href='#' onClick={(e) => videoTabClick('profile', e)}
                                                       className={activeVieoTab === 'profile' ? 'active' : ''}>Profile
                                                    Videos</a></li>
                                                <li><a href='#' onClick={(e) => videoTabClick('top', e)}
                                                       className={activeVieoTab === 'top' ? 'active' : ''}>Top 5
                                                    Videos</a></li>
                                                <li><a href='#' onClick={(e) => videoTabClick('best', e)}
                                                       className={activeVieoTab === 'best' ? 'active' : ''}>Best
                                                    Videos</a></li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-5 align-self-center col-md-6'>
                                            <div className='sort-wrapper'>
                                                <div className='sort-by'>
                                                    <img src={filterPinkIcon} alt='filterPinkIcon'/>
                                                    <Form.Select aria-label="Default select example">
                                                        {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                        <option>Sort by videos by</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='set-date'>
                                                    {/* <input type='date' className='select-date' /> */}
                                                    <div className='icon-wrapper'>
                                                        <img src={dateIcon} alt='dateIcon'/>
                                                    </div>
                                                    <p>Set date range</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='tabs-content-wrapper'>

                                    {activeVieoTab === 'profile' && (
                                        <div className='videos-reels-wrapper'>
                                            <ul className='videos-list'>
                                                <li><img src={tikTokPost1} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost2} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost3} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost4} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost5} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost6} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost7} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost8} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost9} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost10} alt='tiktok-post'/></li>
                                            </ul>
                                        </div>
                                    )}
                                    {activeVieoTab === 'top' && (
                                        <div className='videos-reels-wrapper'>
                                            <ul className='videos-list'>
                                                <li><img src={tikTokPost1} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost2} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost3} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost4} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost5} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost10} alt='tiktok-post'/></li>
                                            </ul>
                                        </div>
                                    )}
                                    {activeVieoTab === 'best' && (
                                        <div className='videos-reels-wrapper'>
                                            <ul className='videos-list'>
                                                <li><img src={tikTokPost1} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost2} alt='tiktok-post'/></li>
                                                <li><img src={tikTokPost10} alt='tiktok-post'/></li>
                                            </ul>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    )}
                    {activeTab === 'chat' && (
                        <div className='main-content-wrapper'>
                            <div className='chart-tab-wrapper'>
                                <div className='title-bar-wrapper'>
                                    <ul className='nav-tab-wrapper'>
                                        <li><a href='#' onClick={(e) => chartTabClick('accountGrowth', e)}
                                               className={activeChartTab === 'accountGrowth' ? 'active' : ''}>Account
                                            Growth</a></li>
                                        <li><a href='#' onClick={(e) => chartTabClick('perWeek', e)}
                                               className={activeChartTab === 'perWeek' ? 'active' : ''}>Videos posted by
                                            day of the week</a></li>
                                        <li><a href='#' onClick={(e) => chartTabClick('perDay', e)}
                                               className={activeChartTab === 'perDay' ? 'active' : ''}>Videos posted on
                                            days on average</a></li>
                                        <li><a href='#' onClick={(e) => chartTabClick('mostUsedHashtags', e)}
                                               className={activeChartTab === 'mostUsedHashtags' ? 'active' : ''}>Most
                                            used hashtags</a></li>
                                        <li><a href='#' onClick={(e) => chartTabClick('commonDurations', e)}
                                               className={activeChartTab === 'commonDurations' ? 'active' : ''}>Most
                                            common durations</a></li>
                                    </ul>
                                </div>
                                {activeChartTab === 'accountGrowth' && (
                                    <div className='tabs-content-wrapper'>
                                        <div className='select-tabs-wrapper'>
                                            <div className='sort-by'>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                    <option>Videos</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>

                                            <div className='select-date sort-by'>
                                                <img src={calendarWhiteIcon} alt='filterPinkIcon'/>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                    <option>Last 6 months</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className='chart-main-wrapper'>
                                            <ul className='chart-detail'>
                                                <li><span>39</span><p>Videos updated</p></li>
                                                <li><span>39</span><p>Likes per video</p></li>
                                                <li><span>39</span><p>Views per video</p></li>
                                                <li><span>39</span><p>Shares per video</p></li>
                                                <li><span>39</span><p>Comments per video</p></li>
                                            </ul>
                                            <div className='chart-wrapper'>
                                                <img src={largeGraph} alt='largeGraph'/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeChartTab === 'perWeek' && (
                                    <div className='tabs-content-wrapper'>
                                        <div className='select-tabs-wrapper'>
                                            <div className='sort-by'>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                    <option>Videos</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>

                                            <div className='select-date sort-by'>
                                                <img src={calendarWhiteIcon} alt='filterPinkIcon'/>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                    <option>Last 6 months</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className='chart-main-wrapper'>
                                            <ul className='chart-detail'>
                                                <li><span>39</span><p>Videos updated</p></li>
                                                <li><span>39</span><p>Likes per video</p></li>
                                                <li><span>39</span><p>Views per video</p></li>
                                                <li><span>39</span><p>Shares per video</p></li>
                                                <li><span>39</span><p>Comments per video</p></li>
                                            </ul>
                                            <div className='chart-wrapper'>
                                                <img src={largeGraph} alt='largeGraph'/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeChartTab === 'perDay' && (
                                    <div className='tabs-content-wrapper'>
                                        <div className='select-tabs-wrapper'>
                                            <div className='sort-by'>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                    <option>Videos</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>

                                            <div className='select-date sort-by'>
                                                <img src={calendarWhiteIcon} alt='filterPinkIcon'/>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                    <option>Last 6 months</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className='chart-main-wrapper'>
                                            <ul className='chart-detail'>
                                                <li><span>39</span><p>Videos updated</p></li>
                                                <li><span>39</span><p>Likes per video</p></li>
                                                <li><span>39</span><p>Views per video</p></li>
                                                <li><span>39</span><p>Shares per video</p></li>
                                                <li><span>39</span><p>Comments per video</p></li>
                                            </ul>
                                            <div className='chart-wrapper'>
                                                <img src={largeGraph} alt='largeGraph'/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeChartTab === 'mostUsedHashtags' && (
                                    <div className='tabs-content-wrapper'>
                                        <div className='select-tabs-wrapper'>
                                            <div className='sort-by'>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                    <option>Videos</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>

                                            <div className='select-date sort-by'>
                                                <img src={calendarWhiteIcon} alt='filterPinkIcon'/>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                    <option>Last 6 months</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className='chart-main-wrapper'>
                                            <ul className='chart-detail'>
                                                <li><span>39</span><p>Videos updated</p></li>
                                                <li><span>39</span><p>Likes per video</p></li>
                                                <li><span>39</span><p>Views per video</p></li>
                                                <li><span>39</span><p>Shares per video</p></li>
                                                <li><span>39</span><p>Comments per video</p></li>
                                            </ul>
                                            <div className='chart-wrapper'>
                                                <img src={largeGraph} alt='largeGraph'/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeChartTab === 'commonDurations' && (
                                    <div className='tabs-content-wrapper'>
                                        <div className='select-tabs-wrapper'>
                                            <div className='sort-by'>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                    <option>Videos</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>

                                            <div className='select-date sort-by'>
                                                <img src={calendarWhiteIcon} alt='filterPinkIcon'/>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option><img src={youtubeShortsIcon} alt='youtubeShortsIcon' className='youtube-shorts-icon' /></option> */}
                                                    <option>Last 6 months</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className='chart-main-wrapper'>
                                            <ul className='chart-detail'>
                                                <li><span>39</span><p>Videos updated</p></li>
                                                <li><span>39</span><p>Likes per video</p></li>
                                                <li><span>39</span><p>Views per video</p></li>
                                                <li><span>39</span><p>Shares per video</p></li>
                                                <li><span>39</span><p>Comments per video</p></li>
                                            </ul>
                                            <div className='chart-wrapper'>
                                                <img src={largeGraph} alt='largeGraph'/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {activeTab === 'analytics' && (
                        <div>
                            <div className='main-content-wrapper'>
                                <TotalDetailCards/>
                            </div>

                        </div>

                    )}

                </div>
            </section>
        </div>
    )
}