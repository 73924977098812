import { annual_packeges, apiKey, lemon_squeezy_store_id, monthly_packeges } from "../services/constant";
import { ApiRequests, paymentApiRequests } from "../services/ApiRequests";
import axios from "axios";
import { parse } from "cookie";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import Modal from "../components/Modal";

export default function Pricing() {
  const [productsData, setProductsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customersData, setCustomersData] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("app-access-token");
  const [annual, setAnnual] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const errorModalOpenRef = useRef(null);


  console.log("products::: ", productsData);
  useEffect(() => {
    // getStore()
    getProduct();
  }, []);
  const getProduct = async () => {
    try {
      // const response = await axios.get('https://api.lemonsqueezy.com/v1/products/1', {
      // headers: {
      //   Accept: 'application/vnd.api+json',
      //   'Content-Type': 'application/vnd.api+json',
      //   Authorization: `Bearer ${apiKey}`,
      // },
      // });

      const { data } = await ApiRequests.getProducts({
        store_id: lemon_squeezy_store_id,   // live
        // store_id: 39021,
        token: token,
      });
      const _product = data?.data;
      // const finalProducts = _product?.slice(-6)
      // console.log('final products ', finalProducts)
      setProductsData(_product);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      // Handle the error
    }
  };
  const getStore = async () => {
    try {
      // const response = await axios.get('https://api.lemonsqueezy.com/v1/stores', {
      //   headers: {
      //     Accept: 'application/vnd.api+json',
      //     'Content-Type': 'application/vnd.api+json',
      //     Authorization: `Bearer ${apiKey}`,
      //   },
      // });
      const { data } = await paymentApiRequests.getStores();

      console.log(data.data); // Handle the response data
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const createPaymentMethod = async ({ id, store_id }) => {
    try {
      const response = await paymentApiRequests.checkOut({
        data: {
          type: "checkouts",
          attributes: {
            store_id,
            variant_id: 32232,
            custom_price: null,
            product_options: {
              name: "",
              description: "",
              media: [],
              redirect_url: "",
              receipt_button_text: "",
              receipt_link_url: "",
              receipt_thank_you_note: "",
              enabled_variants: [],
            },
            checkout_options: {
              embed: false,
              media: true,
              logo: true,
              desc: true,
              discount: true,
              dark: false,
              subscription_preview: true,
              button_color: "#7047EB",
            },
            checkout_data: {
              email: "",
              user: "",
              name: "",
              billing_address: {
                country: "",
                zip: "",
              },
              tax_number: "",
              discount_code: "",
              custom: {
                userId: user?.id
              }
            },
            expires_at: null,
          },
          relationships: {
            store: {
              data: {
                type: "stores",
                id: "32232",
              },
            },
            variant: {
              data: {
                type: "variants",
                id: "90864",
              },
            },
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // const handleUpgradeClick = async (subscriptionId, productID) => {
  //   setLoadingStates((prevState) => ({
  //     ...prevState,
  //     [productID]: true,
  //   }));
  //   try {
  //     const response = await ApiRequests.subscription({
  //       data: { productID, subscriptionId },
  //       token: token,
  //     });
  //     if (response.status === 200) {
  //       toast.success("Subscription updated successfully");
  //       window.location.reload();
  //     } else {
  //       toast.error("Error while updating subscription");
  //     }
  //   } catch (error) {
  //     // Handle network errors or other exceptions
  //     console.error("An error occurred:", error);
  //   } finally {
  //     setLoadingStates((prevState) => ({
  //       ...prevState,
  //       [productID]: false,
  //     }));
  //   }
  // };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedSubscriptionID, setSelectedSubscriptionID] = useState(null);

  // Step 3: Function to open the confirmation dialog
  const openConfirmation = (subscriptionId, productId) => {
    setSelectedProductId(productId);
    setSelectedSubscriptionID(subscriptionId);
    setShowConfirmation(true);
  };

  // Step 4: Update handleUpgradeClick to open the confirmation dialog
  const handleUpgradeClick = (subscriptionId, productID) => {
    openConfirmation(subscriptionId, productID);
  };

  // Step 5: Function to handle the upgrade or cancel
  const handleConfirmation = async (confirmed) => {
    setShowConfirmation(false);
    if (confirmed) {
      setLoadingStates((prevState) => ({
        ...prevState,
        [selectedProductId]: true,
      }));
      try {
        const response = await ApiRequests.subscription({
          data: {
            productID: selectedProductId,
            subscriptionId: selectedSubscriptionID,
          },
          token: token,
        });
        if (response.status === 200 && !response.error) {
          toast.success("Subscription updated successfully");
          window.location.reload();
        } else {
          toast.error("Error while updating subscription");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setLoadingStates((prevState) => ({
          ...prevState,
          [selectedProductId]: false,
        }));
      }
    }
  };

  return (
    <div>
      {loading ? (
        <div className=" flex justify-center items-center">
          <div
            className=""
            style={{ width: "100%", margin: "auto", height: 100 }}
          >
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translate(-50%,-45%)",
                zIndex: "10",
                marginTop: "35px",
                top:'60px'
              }}
              className="loadingio-spinner-interwind-flyom1cz6sv"
            >
              <div className="ldio-zxrz71mlja" style={{ marginTop: 25 }}>
                <div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="pricing-container">
            {/* <button onClick={() => errorModalOpenRef.current.click()}>Modal</button>
            <Modal errorModalOpenRef={errorModalOpenRef} /> */}

            <div
              className="form-check form-switch pb-10 py-12 "
              style={{ paddingLeft: "3.5em", display: "flex" }}
            >
              <input
                className="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-gray-300 bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                style={{ transform: "scale(1.5)" }}
                name="allowEmail"
                checked={annual}
                onChange={(e) => {
                  setAnnual(e.target.checked);
                }}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
              />

              <label
                style={{ fontSize: "17px" }}
                className="form-check-label inline-block text-sm pl-6 text-gray-400 text-left"
                htmlFor="flexSwitchCheckDefault"
              >
                Annual Subscription
              </label>
            </div>
            <div
              className="cards-row"
            >
              {productsData &&
                Array.isArray(productsData) &&
                productsData
                  ?.filter((item) => {
                    // Assuming each product has a unique ID property, replace 'productId' with the actual property name
                    const productId = item?.id;

                    // List of product IDs for beginner packages
                    const beginnerProductIds = monthly_packeges;

                    // List of product IDs for annual packages
                    const annualProductIds = annual_packeges;

                    // Check if the product is for beginners or annual based on the flag
                    return (annual && annualProductIds.includes(productId)) || (!annual && beginnerProductIds.includes(productId));
                  })
                  ?.map(
                    ({
                      id,
                      attributes: {
                        buy_now_url,
                        created_at,
                        description,
                        from_price,
                        name,
                        pay_what_you_want,
                        price,
                        price_formatted,
                        slug,
                        status,
                        status_formatted,
                        store_id,
                      },
                    }) => (
                      <Card
                        className="pricing-card "
                        key={id}

                      >
                        <Card.Body>
                          <div className="icon">
                            <i class="fa-solid fa-bookmark"></i>
                          </div>
                          <div className="heading">
                            {name}
                          </div>
                          <div className="price">
                            {price_formatted}
                          </div>
                          <Card.Text
                            dangerouslySetInnerHTML={{ __html: description }}
                          ></Card.Text>

                          {user.subscribed &&
                            user.subscription &&
                            id === user.subscription.productId ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Button
                                className="btn subscribed"
                                disabled={true}
                              >
                                {user?.subscription.plan}
                              </Button>
                            </div>
                          ) : user?.subscribed ? (
                            <Button
                              className="btn "
                              onClick={() =>
                                handleUpgradeClick(
                                  user.subscription.subscriptionId,
                                  id
                                )
                              }
                              variant="primary large-button"
                            // href={
                            //   buy_now_url +
                            //   `?checkout[email]=${user.email}&checkout[name]=${user.name}&event=subscription_updated`
                            // }
                            >
                              {loadingStates[id] ? "Loading..." : "Upgrade"}
                            </Button>
                          ) : (
                            <Button
                              style={{
                                padding: 10,
                                backgroundColor: "blue",
                                borderRadius: 6,
                                position: "relative",
                                top: 20,
                              }}
                              onClick={() =>
                                createPaymentMethod({ id, store_id })
                              }
                              variant="primary large-button"
                              href={
                                buy_now_url +
                                `?checkout[email]=${user.email}&checkout[name]=${user.name}&checkout[custom][user_id]=${user?.id ? user.id : user._id}`
                              }
                            >
                              Buy Now
                            </Button>
                          )}
                          {/* <Button variant="primary" onClick={() => { createPaymentMethod({ id, store_id }) }}>Buy Now</Button> */}
                        </Card.Body>
                      </Card>
                    )
                  )}
            </div>
          </div>
        </>
      )}
      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-gray-700 p-5 rounded-lg shadow-lg w-96 h-auto animate-fade-in">
            <p className="text-gray-300 mt-2">
              You are about to upgrade your subscription plan. This will provide
              you with access to premium features and benefits.
            </p>
            <p className="text-gray-300 mt-2">
              Please note that you will be charged according to the new plan.
            </p>
            <p className="text-gray-300 mt-4">
              Are you sure you want to proceed with the upgrade?
            </p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => handleConfirmation(true)}
                className="bg-green-500 text-white px-4 py-2 rounded-md mr-2"
              >
                Upgrade
              </button>
              <button
                onClick={() => handleConfirmation(false)}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// export async function getServerSideProps(context) {
//   const { req, res } = context;
//   const cookies = parse(req.headers.cookie || "");
//   const tokens = cookies.tokens;
//   if (!tokens) {
//     return {
//       redirect: {
//         destination: "/login",
//         permanent: false,
//       },
//     };
//   }
//   const tokensObject = JSON?.parse(tokens);
//   const user = tokensObject?.user;

//   // console.log("asad", user);
//   const { data } = await paymentApiRequests.getProducts();

//   // Return props to pass to the protected component
//   return {
//     props: { user, products: data },
//   };
// }
